// initial state
const initialState = () => {
  return {
    isSessionLoggedUser: false,
  };
};
const state = initialState();
const getters = {
  getIsSessionLoggeduser: (state) => state.isSessionLoggedUser,
};
const mutations = {
  setIsSessionLoggeduser: (state, status) => {
    state.isSessionLoggedUser = status;
  },
  clear: (state) => {
    state.isSessionLoggedUser = false;
  },

  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  clear: ({ commit }) => {
    commit("session/clear", null, {
      root: true,
    });
  },
  updateSessionStatus({ commit }, status) {
    commit("setIsSessionLoggeduser", status);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
