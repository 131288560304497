import AuthMiddleware from "@/middleware/auth"

const AllMarketers = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/marketerStats/AllMarketers"
  )
const MarketerData = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/marketerStats/SingleMarketerData"
  )
const AllMarketersData = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/marketerStats/AllMarketerData"
  )
const CompareMarketersData = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/marketerStats/CompareMarketersData"
  )
const MarketerRouter = [
  {
    path: "/all-marketers-data",
    name: "AllMarketers",
    component: AllMarketers,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "MARKETERS DATA",
    },
    children: [
      {
        path: "/all-marketers",
        name: "AllMarketersData",
        component: AllMarketersData,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "VOLUME STATS",
        },
      },
      {
        path: "/marketers/single-marketer",
        name: "MarketerData",
        component: MarketerData,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "VOLUME STATS",
        },
      },
      {
        path: "/marketers/compare-marketers-data",
        name: "CompareMarketersData",
        component: CompareMarketersData,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "VOLUME STATS",
        },
      },
    ],
  },
]

export { MarketerRouter }
