import axios from "@/config/axios";

const initialState = () => {
  return {
    confirmationTests: null,
    qcChartReportData: null,
    qcChartPrintReportData: null,
    qcChartReportValidationErrors: null,
    qcResultFiles: [],
    qcUploadFileStatus: false,
    qcChartChartSvgs: [],
    bloodQcChartReportData: null,
    urinalysisQcResults: null
  };
};

const state = initialState();

const getters = {
  getConfirmationTests: (state) => state.confirmationTests,
  getQCChartReportData: (state) => state.qcChartReportData,
  getQCChartPrintReportData: (state) => state.qcChartPrintReportData,
  getQCChartReportValidationErrors: (state) =>
    state.qcChartReportValidationErrors,
  getQCResultFiles: (state) => state.qcResultFiles,
  getQCUploadFileStatus: (state) => state.qcUploadFileStatus,
  getQCChartChartSvgs: (state) => state.qcChartChartSvgs,
  getBloodQCChartReportData: (state) => state.bloodQcChartReportData,
  getUrinalysisQcResults: (state) => state.urinalysisQcResults
};

const mutations = {
  setConfirmationTests(state, medications) {
    state.confirmationTests = medications;
  },
  setQCChartReportData(state, data) {
    state.qcChartReportData = data;
  },
  setQCChartPrintReportData(state, data) {
    state.qcChartPrintReportData = data;
  },
  setBloodQCChartReportData(state, data) {
    state.bloodQcChartReportData = data;
  },

  setQCChartReportValidationErrors(state, errors) {
    state.qcChartReportValidationErrors = errors;
  },
  setQCResultFiles(state, data) {
    state.qcResultFiles = data;
  },

  setQCUploadFileStatus(state, status) {
    state.qcUploadFileStatus = status;
  },

  setQCChartChartSvgs(state, status) {
    state.qcChartChartSvgs = status;
  },
  setUrinalysisQcResults(state, data) {
    state.urinalysisQcResults = data
  },
  reset(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  fetchAllConfirmationTests: async ({ commit, dispatch }, params) => {
    try {
      commit("setConfirmationTests", null);
      let response = await axios.get("/lab-tests/toxicology/confirmation", {
        params,
      });
      commit("setConfirmationTests", response.data.data);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        });
      }
    }
  },

  fetchQCChartReportData: async ({ commit, dispatch }, params) => {
    try {
      commit("setQCChartReportData", null);
      commit("setQCChartReportValidationErrors", null);
      let response = await axios.post("/toxicology-qc-charts", params);
      commit("setQCChartReportData", response.data.data);
    } catch (err) {
      if (err.response) {
        let errorData = await context.dispatch(
          "errors/errorResponse",
          err.response,
          { root: true }
        );
        commit("setQCChartReportValidationErrors", errorData);
      }
    }
  },
  fetchResultFiles: async ({ commit }, params) => {
    try {
      commit("setQCResultFiles", null);
      const response = await axios.get("/toxicology-qc-charts/result-files", {
        params,
      });
      commit("setQCResultFiles", response.data.data);
    } catch (err) {
      commit("setQCResultFiles", null);
    }
  },

  saveQCDataFiles: async ({ commit, dispatch }, params) => {
    try {
      commit("setQCUploadFileStatus", false);
      await axios.post("result-files-covid", params);
      commit("setQCUploadFileStatus", true);
    } catch (err) {
      commit("setQCUploadFileStatus", false);
      if (err.response) {
        dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  fetchAllBloodQcChartData: async ([commit, dispatch], params) => {
    try {
      commit("setBloodQCChartReportData", null);
      commit("setQCChartReportValidationErrors", null);
      let response = await axios.get("/blood-qc-results", { params });
      // let response = await axios.get("/qc-data", params);
      commit("setBloodQCChartReportData", response.data);
    } catch (err) {
      if (err.response) {
        let errorData = await dispatch("errors/errorResponse", err.response, {
          root: true,
        });
        commit("setQCChartReportValidationErrors", errorData);
      }
    }
  },
  fetchUrinalysisQcResults: async ({ commit }, params) => {
    try {
      commit("setUrinalysisQcResults", null);
      let response = await axios.get("/urinalysis-machine-qc-results", { params });
      commit("setUrinalysisQcResults", response.data.data);
    } catch (err) {
      commit("setUrinalysisQcResults", null);
      if (err.response) {
        dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
