import axios from "@/config/axios";

const initialState = () => {
  return {
    billingCases: [],
    updateCaseBillingStatus: null,
  };
};

const state = initialState();

const getters = {
  getAllBillingCases: (state) => state.billingCases,
  getUpdateCaseBillingStatus: (state) => state.updateCaseBillingStatus,
};

const mutations = {
  setAllBillingCases(state, data) {
    state.billingCases = data;
  },

  setUpdateCaseBillingStatus(state, status) {
    state.updateCaseBillingStatus = status;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllBillingCases: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true });

      const response = await axios.get("/cases", { params });

      commit("setAllBillingCases", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },

  addBillingCases: async ({ commit }, params) => {
    try {
      commit("setUpdateCaseBillingStatus", null);
      let response = await axios.patch("/cases/billing-status", params);
      commit("setUpdateCaseBillingStatus", response.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
