import AuthMiddleware from "@/middleware/auth";

const QCChartReport = () =>
  import(/* webpackChunkName: "qcchart" */ "@/views/QCCharts/QCChartReport");
const QCChatResults = () =>
  import(/* webpackChunkName: "qcchart" */ "@/views/QCCharts/QCChartResult");
const CompleteQCChartResults = () =>
  import(
    /* webpackChunkName: "qcchart" */ "@/views/QCCharts/CompleteQCChartResults"
  );
const ToStartQCChartResults = () =>
  import(
    /* webpackChunkName: "qcchart" */ "@/views/QCCharts/ToStartQCChartResults"
  );
const QCChatFileUpload = () =>
  import(
    /* webpackChunkName: "qcchart" */ "@/views/QCCharts/QCChartFileUpload"
  );
const QCChartReportPrintView = () =>
  import(
    /* webpackChunkName: "qcchart" */ "@/components/QCCharts/QCChartReportPrint"
  );

const QCChartsRouter = [
  {
    path: "/qc-chart",
    name: "QCChartReport",
    component: QCChartReport,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "QC CHARTS",
    },
  },
  {
    path: "/qc-chart/result-files",
    name: "QCChatResults",
    component: QCChatResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "QC CHART-RESULT FILES",
    },
  },

  {
    path: "/qc-chart/result-files/to-start",
    name: "ToStartQCChartResults",
    component: ToStartQCChartResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "QC CHART-RESULT FILES",
    },
  },
  {
    path: "/qc-chart/result-files/complete",
    name: "CompleteQCChartResults",
    component: CompleteQCChartResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "QC CHART-RESULT FILES",
    },
  },
  {
    path: "/qc-chart/file-upload",
    name: "QCChartFileUpload",
    component: QCChatFileUpload,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "QC CHART-RESULT FILES UPLOAD",
    },
  },
];

const QCChartNonChildRoutes = [
  {
    path: "/qc-chart/print",
    name: "QCchart-print",
    component: QCChartReportPrintView,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "QC Chart Report",
    },
  },
];

export { QCChartsRouter, QCChartNonChildRoutes };
