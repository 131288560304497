import AuthMiddleware from "@/middleware/auth";
const PendingCaseView = () =>
  import(
    /* webpackChunkName: "pendingcases" */ "@/views/pendingCases/PendingCaseView"
  );
const EditPendingCase = () =>
  import(
    /* webpackChunkName: "pendingcases" */ "@/views/pendingCases/PendingCasesEdit"
  );

const AllApprovalPendingCases = () =>
  import(
    /* webpackChunkName: "pendingcases" */ "@/views/pendingCases/AllApprovalPendingCases"
  );
const ActiveApprovalPendingCases = () =>
  import(
    /* webpackChunkName: "pendingcases" */ "@/views/pendingCases/ActiveApprovalPendingCases"
  );
const PendingApprovalPendingCases = () =>
  import(
    /* webpackChunkName: "pendingcases" */ "@/views/pendingCases/PendingApprovalPendingCases"
  );
const CompleteApprovalPendingCases = () =>
  import(
    /* webpackChunkName: "pendingcases" */ "@/views/pendingCases/CompleteApprovalPendingCases"
  );
const ArchiveApprovalPendingCases = () =>
  import(
    /* webpackChunkName: "pendingcases" */ "@/views/pendingCases/ArchiveApprovalPendingCases"
  );

const PendingCasesRouter = [
  {
    path: "/all-pending-cases",
    name: "AllApprovalPendingCases",
    component: AllApprovalPendingCases,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "APPROVAL PENDING CASES",
    },
  },

  {
    path: "/all-pending-cases/active",
    name: "ActiveApprovalPendingCases",
    component: ActiveApprovalPendingCases,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "APPROVAL PENDING CASES",
    },
  },
  {
    path: "/all-pending-cases/pending",
    name: "PendingApprovalPendingCases",
    component: PendingApprovalPendingCases,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "APPROVAL PENDING CASES",
    },
  },
  {
    path: "/all-pending-cases/finalized",
    name: "CompleteApprovalPendingCases",
    component: CompleteApprovalPendingCases,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "APPROVAL PENDING CASES",
    },
  },
  {
    path: "/all-pending-cases/rejected",
    name: "RejectedApprovalPendingCases",
    component: ArchiveApprovalPendingCases,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "APPROVAL PENDING CASES",
    },
  },
  {
    path: "/pending-cases/:case_id",
    name: "PendingCaseView",
    component: PendingCaseView,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/pending-cases/:case_id/edit",
    name: "PendingCasesEdit",
    component: EditPendingCase,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EDIT APPROVAL PENDING CASE",
    },
  },
];

export { PendingCasesRouter };
