// import router from '@/router';
// initial state
const initialState = () => {
  return {
    errors: [],
    message: null,
    error: null,
  }
}
const state = initialState()
const getters = {
  getErrors: (state) => state.errors,
  getError: (state) => state.error,
  getMessage: (state) => state.message,
}
const mutations = {
  setErrors: (state, errors) => {
    state.errors = errors
  },
  setError: (state, error) => {
    state.error = error
  },
  setMessage: (state, message) => {
    state.message = message
  },
  clear: (state) => {
    state.errors = []
    state.message = null
    state.error = null
  },

  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  clear: ({ commit }) => {
    commit("errors/clear", null, { root: true })
  },
  // error response
  errorResponse: async (context, errorResponse) => {
    let errorStatusCode = errorResponse.status
    let errorData = {}
    if (errorResponse.data) {
      switch (errorStatusCode) {
        case 422:
          var errors = errorResponse.data.errors
          if (errors && errors.details && errors.details.length) {
            errors.details.forEach((detail) => {
              if (
                detail.key == "insurance" ||
                detail.key == "address" ||
                detail.key == "patient_info" ||
                detail.key == "billing_info" ||
                detail.key == "address" ||
                detail.key == "case_data" ||
                detail.key == "medical_necessities" ||
                detail.key == "settings" ||
                detail.key == "toxicology_order_medical_necessity_fields" ||
                detail.key ==
                  "urinary_tract_infectious_medical_necessity_fields" ||
                detail.key == "urinalysis_order_medical_necessity_fields" ||
                detail.key == "sample_types" ||
                detail.key == "signature_settings"
              ) {
                if (!errorData[detail.path]) {
                  errorData[detail.path] = detail.message
                }
              } else {
                if (!errorData[detail.key]) {
                  errorData[detail.key] = detail.message
                }
              }
            })
          } else {
            errorData["critical_error"] = errorResponse.data.message
          }
          break
        case 401:
        case 403:
          errorData["critical_error"] = errorResponse.data.message

          break

        default:
          errorData["critical_error"] = errorResponse.data.message
          break
      }
    }
    context.commit("errors/setErrors", errorData, { root: true })
    if (errorData["critical_error"]) {
      context.commit("errors/setError", errorData["critical_error"], {
        root: true,
      })
    }

    return errorData
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
