import AuthMiddleware from "@/middleware/auth"

const PhlebioOrders = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/views/phlebioOrders/AllPhlebioOrders"
  )
const PhlebioOrderDetails = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/components/phlebioOrders/PhlebioOrderDetails"
  )
const UpdatePhlebioOrderDetails = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/components/phlebioOrders/UpdatePhlebioOrderDetails"
  )
const PhlebioRequests = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/views/phlebioOrders/AllPhlebioRequests"
  )
const PhlebioCreateAccession = () =>
  import(
    /* webpackChunkName: "phlebio" */ "@/components/phlebioOrders/PhlebioAccession"
  )
const PhlebioRouter = [
  {
    path: "/phlebio-orders",
    name: "AllPhlebioOrders",
    component: PhlebioOrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/phlebio-orders/requests",
    name: "AllPhlebioRequests",
    component: PhlebioRequests,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/phlebio-orders/:phlebio_id",
    name: "PhlebioOrderDetails",
    component: PhlebioOrderDetails,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/update-phlebio-orders/:phlebio_id",
    name: "UpdatePhlebioOrderDetails",
    component: UpdatePhlebioOrderDetails,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHLEBIO ORDERS",
    },
  },
  {
    path: "/phlebio-orders/:phlebio_id/create-accession",
    name: "Create-Accession",
    component: PhlebioCreateAccession,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "CREATE ACCESSION",
    },
  },
]

export { PhlebioRouter }
