import axios from "@/config/axios";

const initialState = () => {
  return {
    allEmailLogs: {},
  };
};

const state = initialState();
const getters = {
  getAllEmailLogs: (state) => state.allEmailLogs,
};

const mutations = {
  setAllEmailLogs(state, data) {
    state.allEmailLogs = data;
  },

  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllEmailLogs: async ({ commit }, params) => {
    try {
      let response = await axios.get("/email-logs", { params });
      commit("setAllEmailLogs", response.data || {});
    } catch (error) {
      commit("setAllEmailLogs", {});
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
