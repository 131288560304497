import "./assets/css/base.scss";

import "element-plus/theme-chalk/display.css";
import "./assets/scss/app.scss";

// main.ts
import { createApp } from "vue";
import ElementPlus from "element-plus";
import locale from "element-plus/lib/locale/lang/en";
import "element-plus/dist/index.css";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import App from "./App.vue";
import GlobalHelper from "@/mixins/GlobalHelper";
import * as Icons from "@element-plus/icons-vue";
import HighchartsVue from "highcharts-vue";
const app = createApp(App);
for (let i in Icons) {
  app.component(i, Icons[i]);
}
app.use(ElementPlus);
app.use(router);
app.use(HighchartsVue);
app.use(ElementPlus, {
  locale,
});

app.mixin(GlobalHelper);
app.use(store);
app.use(VueAxios, axios);
app.mount("#app");