import axios from "@/config/axios";
import * as PrivateAxios from "axios";

const initialState = () => {
  return {
    allPendingCases: {},
    patientInformationUpdateStatus: false,
    singlePatientDetails: {},
    caseInformationStatus: false,
    pendingCaseData: {},
    caseConvertionStatus: null,
    attachmentDeleteStatus: false,
    internalDocumentDeleteStatus: false,
    noteAddStatus: false,
    noteUpdateStatus: false,
    noteDeleteStatus: false,
    billingInformationDeleteStatus: false,
    showMedication: false,
    newAddedMedication: null,
    caseRejectionStatus: null,
  };
};
const state = initialState();

const getters = {
  getAllPendingCases: (state) => state.allPendingCases,
  getsinglePatientDetails: (state) => state.singlePatientDetails,
  getPatientInformationUpdateStatus: (state) =>
    state.patientInformationUpdateStatus,
  getCaseInformationUpdateStatus: (state) => state.caseInformationStatus,

  getPendingCaseInforamtion: (state) => state.pendingCaseData,
  getCaseConvertionStatus: (state) => state.caseConvertionStatus,
  getNoteAddStatus: (state) => state.noteAddStatus,
  getNoteUpdateStatus: (state) => state.noteUpdateStatus,
  getNoteDeleteStatus: (state) => state.noteDeleteStatus,
  getInternalDocumentDeleteStatus: (state) =>
    state.internalDocumentDeleteStatus,
  getAttachmentDeleteStatus: (state) => state.attachmentDeleteStatus,
  getFileUploadURLForAttachments: (state) => state.attachmentUploadURL,
  getFileUploadRefIdForAttachment: (state) => state.attachmentFileId,
  getAttachmentUploadStatusUpdated: (state) =>
    state.attachmentUploadStatusUpdated,
  getAttachmentUploadStatus: (state) => state.attachmentUploadStatus,
  getDeleteCaseBillingInfoStatus: (state) =>
    state.billingInformationDeleteStatus,
  getMedication: (state) => state.showMedication,
  getCaseRejectionStatus: (state) => state.caseRejectionStatus,
};
const mutations = {
  setallPendingCases(state, data) {
    state.allPendingCases = data;
  },
  setSinglePatientDetails(state, data) {
    state.singlePatientDetails = data;
  },
  setpatientInformationUpdateStatus(state, status) {
    state.patientInformationUpdateStatus = status;
  },
  setCaseInformationStatus(state, status) {
    state.caseInformationStatus = status;
  },

  setDeleteCaseBillingInfoStatus(state, status) {
    state.billingInformationDeleteStatus = status;
  },

  setPendingCaseData(state, data) {
    state.pendingCaseData = data;
  },
  setCaseConvertionStatus(state, status) {
    state.caseConvertionStatus = status;
  },
  setAttachmentDeleteStatus(state, data) {
    state.attachmentDeleteStatus = data;
  },
  setInternalDocumentDeleteStatus(state, data) {
    state.internalDocumentDeleteStatus = data;
  },
  setNoteAddStatus(state, data) {
    state.noteAddStatus = data;
  },
  setNoteUpdateStatus(state, data) {
    state.noteUpdateStatus = data;
  },
  setNoteDeleteStatus(state, data) {
    state.noteDeleteStatus = data;
  },
  setFileUploadURLForAttachments(state, data) {
    state.attachmentUploadURL = data;
  },
  setFileUploadRefIdForAttachment(state, data) {
    state.attachmentFileId = data;
  },
  setAttachmentUploadStatusUpdated(state, data) {
    state.attachmentUploadStatusUpdated = data;
  },
  setAttachmentUploadStatus(state, data) {
    state.attachmentUploadStatus = data;
  },
  setShowMedication(state, data) {
    state.showMedication = data;
  },

  setNewlyAddedMedication(state, data) {
    state.newAddedMedication = data;
  },
  setCaseRejectionStatus(state, data) {
    state.caseRejectionStatus = data;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  async fetchAllPendingCases({ commit }, params) {
    try {
      commit("setallPendingCases", null);
      const response = await axios.get("/pending-cases", { params });
      commit("setallPendingCases", response.data);
    } catch (err) {
      commit("setallPendingCases", null);
      console.log(err);
    }
  },
  async fetchSingleCaseInformation({ commit }, params) {
    try {
      commit("setSinglePatientDetails", null);
      const response = await axios.get("/pending-cases/" + params.case_id);
      commit("setSinglePatientDetails", response.data.data);
      if (
        response.data.data.case_types.indexOf("TOXICOLOGY") > -1 ||
        response.data.data.case_types.indexOf("PGX_TEST") > -1
      ) {
        commit("setShowMedication", true);
      } else {
        commit("setShowMedication", false);
      }
    } catch (err) {
      commit("setSinglePatientDetails", null);
    }
  },
  async updatePatientInformation({ commit, dispatch }, params) {
    let case_id = params.case_id;
    delete params.case_id;
    try {
      commit("errors/clear", null, { root: true });

      commit("setpatientInformationUpdateStatus", null);
      const response = await axios.patch(
        "/pending-cases/" + case_id + "/patient-information",
        params
      );
      commit("setpatientInformationUpdateStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async updateCaseInformation({ commit, dispatch }, params) {
    let case_id = params.case_id;
    delete params.case_id;
    try {
      commit("errors/clear", null, { root: true });

      commit("setCaseInformationStatus", null);
      const response = await axios.put(`/pending-cases/${case_id}`, params);
      commit("setCaseInformationStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },

  deleteBillingInfo: async ({ commit }, case_id) => {
    try {
      commit("setDeleteCaseBillingInfoStatus", false);
      await axios.delete(`pending-cases/${case_id}/billing-information`);
      commit("setDeleteCaseBillingInfoStatus", true);
    } catch (err) {
      commit("setDeleteCaseBillingInfoStatus", false);
    }
  },

  async addToPendingCase({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true });

      commit("setPendingCaseData", null);
      const response = await axios.post("/pending-cases", params);
      commit("setPendingCaseData", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async convertToCase({ commit, dispatch }, params) {
    try {
      let case_id = params.case_id;
      delete params.case_id;
      commit("errors/clear", null, { root: true });

      commit("setCaseConvertionStatus", null);
      const response = await axios.post(
        "/pending-cases/" + case_id + "/create-accession",
        params
      );
      commit("setCaseConvertionStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async rejectPendingCase({ commit, dispatch }, params) {
    try {
      let case_id = params.case_id;
      delete params.case_id;
      commit("errors/clear", null, { root: true });

      commit("setCaseRejectionStatus", null);
      const response = await axios.patch(
        "/pending-cases/" + case_id + "/reject",
        params
      );
      commit("setCaseRejectionStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async deleteAttachment({ commit, dispatch }, params) {
    try {
      commit("setAttachmentDeleteStatus", false);
      await axios.delete(
        "/pending-cases/" + params.case_id + "/attachements/" + params.id,
        {
          data: params,
        }
      );
      commit("setAttachmentDeleteStatus", true);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async deleteInternalDocument({ commit, dispatch }, params) {
    try {
      commit("setInternalDocumentDeleteStatus", false);
      await axios.delete(
        "/pending-cases/" + params.case_id + "/internal-documents/" + params.id,
        {
          data: params,
        }
      );
      commit("setInternalDocumentDeleteStatus", true);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },

  addNote: async ({ commit, dispatch }, params) => {
    try {
      commit("setNoteAddStatus", false);
      await axios.post(
        "/pending-cases/" + params.case_id + "/internal-notes",
        params
      );
      commit("setNoteAddStatus", true);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async updateNote({ commit, dispatch }, params) {
    try {
      commit("setNoteUpdateStatus", false);
      await axios.patch(
        "pending-cases/" + params.case_id + "/internal-notes/" + params.id,
        params
      );
      commit("setNoteUpdateStatus", true);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async deleteNote({ commit, dispatch }, params) {
    try {
      commit("setNoteDeleteStatus", false);
      await axios.delete(
        "pending-cases/" + params.case_id + "/internal-notes/" + params.id,
        {
          data: params,
        }
      );
      commit("setNoteDeleteStatus", true);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async fetchDocumentUploadUrl({ commit, dispatch }, params) {
    try {
      commit("setFileUploadURLForAttachments", null);
      commit("setFileUploadRefIdForAttachment", null);
      const response = await axios.post(
        "/pending-cases/" + params.pending_case_id + "/" + params.type,
        params
      );
      const data = response.data.data;
      commit("setFileUploadURLForAttachments", data.target_url);
      commit("setFileUploadRefIdForAttachment", data.hash);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },

  async updateDocumentUploadStatus({ commit, dispatch }, params) {
    try {
      commit("setAttachmentUploadStatusUpdated", false);
      await axios.patch(
        "/pending-cases/" +
          params.pending_case_id +
          "/" +
          params.type +
          "/" +
          params.hash +
          "/status"
      );
      commit("setAttachmentUploadStatusUpdated", true);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  async uploadDocumentToGeneratedUrl({ commit, dispatch }, params) {
    try {
      commit("setAttachmentUploadStatus", false);
      let pAxios = PrivateAxios.create({});
      await pAxios.put(params.upload_url, params.file, {
        headers: {
          "Content-Type": params.file.type
        }
      });
      commit("setAttachmentUploadStatus", true);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },

  clear: ({ commit }) => {
    commit("clear", { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
