import AuthMiddleware from "@/middleware/auth";

const AllPhysicians = () =>
  import(
    /* webpackChunkName: "physician" */ "@/views/physicians/AllPhysicians.vue"
  );
const ActivePhysicians = () =>
  import(
    /* webpackChunkName: "physician" */ "@/views/physicians/ActivePhysicians.vue"
  );
const InActivePhysicians = () =>
  import(
    /* webpackChunkName: "physician" */ "@/views/physicians/InActivePhysicians.vue"
  );
const AddNewPhysician = () =>
  import(
    /* webpackChunkName: "physician" */ "@/views/physicians/AddNewPhysician.vue"
  );
const PhysicianProfileView = () =>
  import(
    /* webpackChunkName: "physician" */ "@/views/physicians/PhysicianProfileView.vue"
  );
const PhysicianEdit = () =>
  import(
    /* webpackChunkName: "physician" */ "@/views/physicians/PhysicianEdit.vue"
  );

const PhysicianRouter = [
  {
    path: "/physicians",
    name: "AllPhysicians",
    beforeEnter: AuthMiddleware.user,
    component: AllPhysicians,
    meta: {
      title: "PHYSICIANS",
    },
  },

  {
    path: "/physicians/active",
    name: "ActivePhysicians",
    beforeEnter: AuthMiddleware.user,
    component: ActivePhysicians,
    meta: {
      title: " PHYSICIANS",
    },
  },
  {
    path: "/physicians/inactive",
    name: "InActivePhysicians",
    beforeEnter: AuthMiddleware.user,
    component: InActivePhysicians,
    meta: {
      title: "PHYSICIANS",
    },
  },
  {
    path: "/physician",
    name: "AddNewPhysician",
    component: AddNewPhysician,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHYSICIAN",
    },
  },
  {
    path: "/physicians/:physician_id",
    name: "PhysicianProfileView",
    component: PhysicianProfileView,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHYSICIANS",
    },
  },

  {
    path: "/physicians/:physician_id/edit",
    name: "PhysicianEdit",
    component: PhysicianEdit,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "PHYSICIAN",
    },
  },
];

export { PhysicianRouter };
