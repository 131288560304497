import AuthMiddleware from "@/middleware/auth";

const allResults = () =>
  import(/* webpackChunkName: "resultfiles" */ "@/views/results/AllResults");
const ActiveResults = () =>
  import(/* webpackChunkName: "resultfiles" */ "@/views/results/ActiveResults");
const ToStartResults = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/ToStartResults"
  );
const FailedResults = () =>
  import(/* webpackChunkName: "resultfiles" */ "@/views/results/FailedResults");
const ProcessingResults = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/ProcessingResults"
  );
const CompleteResults = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/CompleteResults"
  );
const ArchiveResults = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/ArchiveResults"
  );
const allResultsFileUpload = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/AllResultsFileUpload.vue"
  );
const allResultsViewProgress = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/AllResultsViewProgress.vue"
  );
const ToStartResultFilesViewProgress = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/ToStartResultFilesViewProgress.vue"
  );
const FailedResultFilesViewProgress = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/FailedResultFilesViewProgress.vue"
  );
const ProcessingResultFilesViewProgress = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/ProcessingResultFilesViewProgress.vue"
  );
const CompleteResultFilesViewProgress = () =>
  import(
    /* webpackChunkName: "resultfiles" */ "@/views/results/CompleteResultFilesViewProgress.vue"
  );

const ResultFilesRouter = [
  {
    path: "/result-files",
    name: "allResults",
    component: allResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULT FILES",
    },
  },

  {
    path: "/result-files/active",
    name: "ActiveResults",
    component: ActiveResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULT FILES",
    },
  },
  {
    path: "/result-files/to-start",
    name: "ToStartResults",
    component: ToStartResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULT FILES",
    },
  },
  {
    path: "/result-files/failed",
    name: "FailedResults",
    component: FailedResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULT FILES",
    },
  },
  {
    path: "/result-files/processing",
    name: "ProcessingResults",
    component: ProcessingResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULT FILES",
    },
  },
  {
    path: "/result-files/complete",
    name: "CompleteResults",
    component: CompleteResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULT FILES",
    },
  },
  {
    path: "/result-files/archive",
    name: "ArchiveResults",
    component: ArchiveResults,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULT FILES",
    },
  },
  {
    path: "/result-files/upload",
    name: "allResultsFileUpload",
    component: allResultsFileUpload,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "UPLOAD A FILE",
    },
  },
  {
    path: "/result-files/:result_file_id",
    name: "viewprogress",
    component: allResultsViewProgress,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULTS FILES",
    },
  },
  {
    path: "/result-files/:result_file_id/to-start",
    name: "ToStartResultFilesViewProgress",
    component: ToStartResultFilesViewProgress,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULTS FILES",
    },
  },
  {
    path: "/result-files/:result_file_id/processing",
    name: "ProcessingResultFilesViewProgress",
    component: ProcessingResultFilesViewProgress,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULTS FILES",
    },
  },
  {
    path: "/result-files/:result_file_id/failed",
    name: "FailedResultFilesViewProgress",
    component: FailedResultFilesViewProgress,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULTS FILES",
    },
  },
  {
    path: "/result-files/:result_file_id/complete",
    name: "CompletedResultFiles",
    component: CompleteResultFilesViewProgress,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RESULTS FILES",
    },
  },
];

export { ResultFilesRouter };
