import AuthMiddleware from "@/middleware/auth";

const AllReceivedSendOuts = () =>
  import(
    /* webpackChunkName: "receivedsendouts" */ "@/views/sendOuts/AllReceivedSendOuts.vue"
  );
const ReceivedSendOutView = () =>
  import(
    /* webpackChunkName: "receivedsendouts" */ "@/views/sendOuts/ReceivedSendOutView.vue"
  );
const ActiveReceivedSendOuts = () =>
  import(
    /* webpackChunkName: "receivedsendouts" */ "@/views/sendOuts/ActiveReceivedSendOuts.vue"
  );
const CompleteReceivedSendOuts = () =>
  import(
    /* webpackChunkName: "receivedsendouts" */ "@/views/sendOuts/CompleteReceivedSendOuts.vue"
  );

const ReceivedSendOutsRouter = [
  {
    path: "/all-received-send-outs",
    name: "allReceivedSendOuts",
    component: AllReceivedSendOuts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RECEIVED SEND OUTS",
    },
  },

  {
    path: "/all-received-send-outs/:send_out_id",
    name: "ReceivedSendOutView",
    component: ReceivedSendOutView,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RECEIVED SEND OUTS",
    },
  },
  {
    path: "/all-received-send-outs/active",
    name: "ActiveReceivedSendOuts",
    component: ActiveReceivedSendOuts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RECEIVED SEND OUTS",
    },
  },
  {
    path: "/all-received-send-outs/finalized",
    name: "CompleteReceivedSendOuts",
    component: CompleteReceivedSendOuts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "RECEIVED SEND OUTS",
    },
  },
];

export { ReceivedSendOutsRouter };
