import axios from "@/config/axios";

const initialState = () => {
  return {
    allLabTests: [],
  };
};

const state = initialState();

const getters = {
  getAllLabTests: (state) => state.allLabTests,
};

const mutations = {
  setAllLabTests(state, data) {
    state.allLabTests = data;
  },

  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllLabTests: async ({ commit, dispatch }, params) => {
    try {
      commit("setAllLabTests", []);
      let response = await axios.get("/lab-tests", { params });
      commit("setAllLabTests", response.data.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
