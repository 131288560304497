import AuthMiddleware from "@/middleware/auth";

const AllProfiles = () =>
  import(
    /* webpackChunkName: "All Profiles" */ "@/components/testProfiles/AllTestProfiles.vue"
  );
const AddTestProfile = () => import(
    /* webpackChunkName: "All Profiles" */ "@/components/testProfiles/AddTestProfiles.vue"
);
const UpdateTestProfile = () => import(
    /* webpackChunkName: "All Profiles" */ "@/components/testProfiles/AddTestProfiles.vue"
);
const testProfileRouter = [
  {
    path: "/test-profiles",
    name: "AllProfiles",
    component: AllProfiles,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ALL TEST PROFILES",
    },
  },
  {
    path: "/test-profiles-add",
    name: "AddTestProfile",
    component: AddTestProfile,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ADD NEW PROFILE",
    },
  },
  {
    path: "/test-profiles-update/:profile_id",
    name: "UpdateTestProfile",
    component: UpdateTestProfile,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "UPDATE NEW PROFILE",
    },
  },
];

export { testProfileRouter };
