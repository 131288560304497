import axios from "@/config/axios";
import * as PrivateAxios from "axios";

const initiateState = () => {
  return {
    patientReportData: null,
    emailPatientReportData: null,
    patientReportPreviewURL: null,
    patientReportAccessStatus: false,
    patientReportEmailAccessStatus: false,
    reportIframeURL: null,
    validateWithDOBData: null,
    validateEmailWithDOBData: null,
    resendOTPStatus: null,
    resendOTPEmailStatus: null,
    patientReportEmailPreviewURL: null,
  };
};
const state = initiateState();
const getters = {
  getPatientReportData: (state) => state.patientReportData,
  getEmailPatientReportData: (state) => state.emailPatientReportData,
  getPatientReportPreviewURL: (state) => state.patientReportPreviewURL,
  getPatientReportAccessStatus: (state) => state.patientReportAccessStatus,
  getPatientReportEmailAccessStatus: (state) =>
    state.patientReportEmailAccessStatus,
  getReportIframeURL: (state) => state.reportIframeURL,
  getValidateWithDOBData: (state) => state.validateWithDOBData,
  getValidateEmailWithDOBData: (state) => state.validateEmailWithDOBData,
  getResendOTPStatus: (state) => state.resendOTPStatus,
  getResendOTPEmailStatus: (state) => state.resendOTPEmailStatus,
  getPatientReportPReviewEmailURL: (state) =>
    state.patientReportEmailPreviewURL,
};
const mutations = {
  setPatientReportData(state, data) {
    state.patientReportData = data;
  },
  setEmailPatientReportData(state, data) {
    state.emailPatientReportData = data;
  },
  setPatientReportPreviewURL(state, url) {
    state.patientReportPreviewURL = url;
  },
  setPatientReportAccessStatus(state, status) {
    state.patientReportAccessStatus = status;
  },
  setPatientReportEmailAccessStatus(state, data) {
    state.patientReportEmailAccessStatus = data;
  },
  setPatientReportEmailPreviewURL(state, url) {
    state.patientReportEmailPreviewURL = url;
  },
  setReportIframeURL(state, url) {
    state.reportIframeURL = url;
  },
  setValidateWithDOBData(state, data) {
    state.validateWithDOBData = data;
  },
  setValidateEmailWithDOBData(state, data) {
    state.validateEmailWithDOBData = data;
  },
  setResendOTPStatus(state, status) {
    state.resendOTPStatus = status;
  },
  setResendOTPEmailStatus(state, status) {
    state.resendOTPEmailStatus = status;
  },

  reset: (state) => {
    const initial = initiateState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  fetchPatientReportData: async ({ commit, dispatch }, reportId) => {
    try {
      commit("setPatientReportData", null);
      commit("errors/clear", null, { root: true });
      const { data } = await axios.get(`/sms-data/${reportId}`);
      commit("setPatientReportData", data.data);
    } catch (error) {
      commit("setPatientReportData", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchPatientEmailReportData: async ({ commit, dispatch }, reportId) => {
    try {
      commit("setEmailPatientReportData", null);
      commit("errors/clear", null, { root: true });
      const { data } = await axios.get(`/email-data/${reportId}`);
      commit("setEmailPatientReportData", data.data);
    } catch (error) {
      commit("setEmailPatientReportData", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  validateWithDOB: async ({ commit, dispatch }, { reportId, params }) => {
    try {
      commit("setValidateWithDOBData", null);
      commit("errors/clear", null, { root: true });
      const { data } = await axios.post(
        `/patient-birth-date/${reportId}`,
        params
      );
      commit("setValidateWithDOBData", data.data);
    } catch (error) {
      commit("setValidateWithDOBData", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  validateEmailWithDOB: async ({ commit, dispatch }, { reportId, params }) => {
    try {
      commit("setValidateEmailWithDOBData", null);
      commit("errors/clear", null, { root: true });
      const { data } = await axios.post(
        `/patient-birth-date-email/${reportId}`,
        params
      );
      commit("setValidateEmailWithDOBData", data.data);
    } catch (error) {
      commit("setValidateEmailWithDOBData", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  resendOTP: async ({ commit, dispatch }, { reportId }) => {
    try {
      commit("setResendOTPStatus", null);
      commit("errors/clear", null, { root: true });
      const { data } = await axios.post(`/resend-sms-otp/${reportId}`);
      commit("setResendOTPStatus", data.data);
    } catch (error) {
      commit("setResendOTPStatus", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  resendOTPEmail: async ({ commit, dispatch }, { reportId, params }) => {
    try {
      commit("setResendOTPEmailStatus", null);
      commit("errors/clear", null, { root: true });
      const { data } = await axios.post(
        `/resend-email-otp/${reportId}`,
        params
      );
      commit("setResendOTPEmailStatus", data.data);
    } catch (error) {
      commit("setResendOTPEmailStatus", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  checkEmailReportPreview: async (
    { commit, dispatch },
    { reportId, params }
  ) => {
    try {
      commit("setPatientReportEmailAccessStatus", false);
      commit("setPatientReportEmailPreviewURL", null);
      commit("errors/clear", null, { root: true });

      const { data } = await axios.post(
        `/patient-case-reports/${reportId}`,
        params
      );
      commit("setPatientReportEmailAccessStatus", true);
      commit("setPatientReportEmailPreviewURL", data.data);
    } catch (error) {
      commit("setPatientReportEmailAccessStatus", false);
      commit("setPatientReportEmailPreviewURL", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  checkPasswordToReportPreview: async (
    { commit, dispatch },
    { reportId, params }
  ) => {
    try {
      commit("setPatientReportAccessStatus", false);
      commit("setPatientReportPreviewURL", null);
      commit("errors/clear", null, { root: true });

      const { data } = await axios.post(
        `/patient-case-report/${reportId}`,
        params
      );
      commit("setPatientReportAccessStatus", true);
      commit("setPatientReportPreviewURL", data.data);
    } catch (error) {
      commit("setPatientReportAccessStatus", false);
      commit("setPatientReportPreviewURL", null);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchReportIframeURL: async ({ commit }, { reportURL }) => {
    try {
      commit("setReportIframeURL", null);
      let paxios = PrivateAxios.create({});
      let response = await paxios({
        url: reportURL, //your url
        method: "GET",
        responseType: "arraybuffer", // important
      });
      const pdfBuffer = response.data;

      const url = window.URL.createObjectURL(
        new Blob([pdfBuffer], { type: "application/pdf" })
      );
      commit("setReportIframeURL", url);
    } catch (err) {
      console.log(err);
      commit("setReportIframeURL", null);
    }
  },
};
export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
};
