import axios from "@/config/axios";

const initialState = () => {
  return {
    allBranches: [],
    singleBranch: null,
    branchAddStatus: false,
    branchValidationErrors: null,
    addedBranchData: null,
    branchDeleteStatus: null,
    branchDeleteError: null,
    multipleHospitalBranches: [],
    singleSetting: null,
    settingsAddStatus: false,
  };
};

const state = initialState();

const getters = {
  getAllBranches: (state) => state.allBranches,
  getSingleBranch: (state) => state.singleBranch,
  getBranchAddStatus: (state) => state.branchAddStatus,
  getAddedBranchData: (state) => state.addedBranchData,
  getBranchValidationErrors: (state) => state.branchValidationErrors,
  getBranchDeleteStatus: (state) => state.branchDeleteStatus,
  getBranchDeleteError: (state) => state.branchDeleteError,
  getMultipleHospitalBranches: (state) => state.multipleHospitalBranches,
  getSingleSetting: (state) => state.singleSetting,
  getSettingsAddStatus: (state) => state.settingsAddStatus,
};

const mutations = {
  setAllBranches(state, dataBranches) {
    state.allBranches = dataBranches;
  },
  setSingleBranch(state, singleBranch) {
    state.singleBranch = singleBranch;
  },
  setBranchValidationErrors(state, data) {
    state.branchValidationErrors = data;
  },
  setBranchAddStatus(state, status) {
    state.branchAddStatus = status;
  },

  setAddedBranchData(state, data) {
    state.addedBranchData = data;
  },

  setBranchDeleteError(state, data) {
    state.branchDeleteError = data;
  },

  setBranchDeleteStatus(state, data) {
    state.branchDeleteStatus = data;
  },

  setMultipleHospitalBranches(state, dataBranches) {
    state.multipleHospitalBranches = dataBranches;
  },
  setSingleSetting(state, singleSetting) {
    state.singleSetting = singleSetting;
  },
  setSettingsAddStatus(state, status) {
    state.settingsAddStatus = status;
  },

  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllBranches: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", null, { root: true });
      commit("setAllBranches", []);
      let response = await axios.get(
        "/hospitals/" + params.hospital_id + "/locations",
        { params }
      );

      commit("setAllBranches", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchMultipleHospitalBranches: async ({ commit }, params) => {
    try {
      commit("setMultipleHospitalBranches", []);
      const branchData = await axios.post("/hospitals/locations", params);
      commit("setMultipleHospitalBranches", branchData.data.data);
    } catch (error) {
      commit("setMultipleHospitalBranches", []);
    }
  },
  fetchSingleBranch: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", [], { root: true });
      commit("setSingleBranch", null);

      let response = await axios.get(
        "/hospitals/" + params.hospital_id + "/locations/" + params.location_id
      );

      commit("setSingleBranch", response.data.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  addBranch: async ({ commit, dispatch }, params) => {
    try {
      commit("setBranchValidationErrors", {});
      commit("setBranchAddStatus", false);
      const branchData = await axios.post(
        "/hospitals/" + params.hospital_id + "/locations",
        params
      );
      commit("setAddedBranchData", branchData.data.data);
      commit("setBranchAddStatus", true);
    } catch (error) {
      commit("setBranchAddStatus", false);
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setBranchValidationErrors", errorData);
      }
    }
  },
  editBranch: async ({ commit, dispatch }, params) => {
    try {
      commit("setBranchValidationErrors", {});
      commit("setBranchAddStatus", false);
      const branchData = await axios.put(
        "/hospitals/" + params.hospital_id + "/locations/" + params.location_id,
        params
      );
      commit("setAddedBranchData", branchData.data);
      commit("setBranchAddStatus", branchData.data);
    } catch (error) {
      commit("setBranchAddStatus", false);
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setBranchValidationErrors", errorData);
      }
    }
  },

  deleteBranch: async ({ commit }, params) => {
    try {
      commit("setBranchDeleteStatus", false);
      await axios.delete(
        "/hospitals/" + params.hospital_id + "/locations/" + params.location_id
      );
      commit("setBranchDeleteStatus", true);
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setBranchDeleteError", error.data.message);
      } else {
        commit("setBranchDeleteError", "Error while deleting Branch");
      }
    }
  },

  getHospitalSettings: async ({ commit }, params) => {
    try {
      commit("setSingleSetting", null);
      let response = await axios.get(
        "/hospitals/" + params.hospital_id + "/settings"
      );
      commit("setSingleSetting", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  updateHospitalSettings: async ({ commit }, { params, hospital_id }) => {
    try {
      commit("setBranchValidationErrors", {});
      commit("setSettingsAddStatus", false);
      const response = await axios.post(
        "/hospitals/" + hospital_id + "/settings",
        params
      );
      commit("setSettingsAddStatus", response.data);
    } catch (error) {
      commit("setSettingsAddStatus", false);
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setBranchValidationErrors", errorData);
      }
    }
  },
  clearValidationErrors: ({ commit }) => {
    commit("setBranchValidationErrors", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
