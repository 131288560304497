import axios from "@/config/axios";

const initiateState = () => {
  return {
    logisticData: null,
    logisticValidationErrors: false,
    addLogisticStatus: null,
  };
};
const state = initiateState();
const getters = {
  getLogisticData: (state) => state.logisticData,
  getLogisticValidationErrors: (state) => state.logisticValidationErrors,
  getAddLogisticStatus: (state) => state.addLogisticStatus,
};
const mutations = {
  setLogisticData(state, data) {
    state.logisticData = data;
  },

  setAddLogisticStatus(state, status) {
    state.addLogisticStatus = status;
  },

  setLogisticValidationErrors(state, data) {
    state.logisticValidationErrors = data;
  },
  reset: (state) => {
    const initial = initiateState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  addLogistic: async ({ commit, dispatch }, params) => {
    try {
      commit("setLogisticValidationErrors", {});
      commit("setAddLogisticStatus", null);

      const logisticData = await axios.post("/logistics-requests", params);
      setLogisticData;
      commit("setLogisticData", logisticData.data);
      commit("setAddLogisticStatus", response.data);
    } catch (error) {
      await dispatch("errors/errorResponse", error.response, {
        root: true,
      });
    }
  },
};
export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
};
