import axios from "@/config/axios";

const initialState = () => {
  return {
    allLabs: [],
    singleLab: null,
    labs: [],
    catogories: [],
    labAddStatus: false,
    addedLabData: null,
    labDeleteStatus: null,
    labDeleteError: null,
    testPanels: [],
    logoAddStatus: null,
    labLogo: null,
    allTimeZones: [],
    caseTypeWorkSheets: [],
    workSheetCreateStatus: false,
    singleWorkSheetDetails: [],
    testConfigStatus: false
  };
};

const state = initialState();

const getters = {
  getAllLabs: (state) => state.allLabs,
  getSingleLab: (state) => state.singleLab,
  getLabs: (state) => state.labs,
  getCatogories: (state) => state.catogories,
  getLabAddStatus: (state) => state.labAddStatus,
  getAddedLabData: (state) => state.addedLabData,
  getLabValidationErrors: (state) => state.labValidationErrors,
  getLabDeleteStatus: (state) => state.labDeleteStatus,
  getLabDeleteError: (state) => state.labDeleteError,
  getTestPanels: (state) => state.testPanels,
  getLabLogoUpdateError: (state) => state.logoUpdateError,
  getLogoAddStatus: (state) => state.logoAddStatus,
  getLabLogo: (state) => state.labLogo,
  getAllTimeZones: (state) => state.allTimeZones,
  getCaseTypeWorkSheets: (state) => state.caseTypeWorkSheets,
  getCreateWorkSheetStaus: (state) => state.workSheetCreateStatus,
  getSingleWorkSheetDetails: (state) => state.singleWorkSheetDetails,
  getTestConfigUpdateStatus: (state) => state.testConfigStatus
};

const mutations = {
  setCatogories(state, catogory) {
    state.catogories = catogory;
  },
  setLabs(state, lab) {
    state.labs = lab;
  },
  setAllLabs(state, dataLabs) {
    state.allLabs = dataLabs;
  },
  setSingleLab(state, singleLab) {
    state.singleLab = singleLab;
  },

  setLabAddStatus(state, status) {
    state.labAddStatus = status;
  },

  setAddedLabData(state, data) {
    state.addedLabData = data;
  },

  setLabDeleteError(state, data) {
    state.labDeleteError = data;
  },

  setLabDeleteStatus(state, data) {
    state.labDeleteStatus = data;
  },

  setLogoAddStatus(state, data) {
    state.logoAddStatus = data;
  },

  setTestPanels(state, data) {
    state.testPanels = data;
  },

  setLabLogoUpdateError(state, data) {
    state.logoUpdateError = data;
  },

  setLabLogo(state, data) {
    state.labLogo = data;
  },

  setAllTimeZones(state, data) {
    state.allTimeZones = data;
  },
  setWorkSheets(state, data) {
    state.caseTypeWorkSheets = data;
  },
  setWorkSheetCreateStatus(state, data) {
    state.workSheetCreateStatus = data
  },
  setWorkSheetDetails(state, data) {
    state.singleWorkSheetDetails = data
  },
  setTestConfigStatus(state, data) {
    state.testConfigStatus = data
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllLabs1: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], {
        root: true,
      });

      let response = await axios.get("/labs", {
        params,
      });
      commit("setLabs", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchAllLabs: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], {
        root: true,
      });
      commit("setAllLabs", []);
      let response = await axios.get("/labs", {
        params,
      });
      commit("setAllLabs", response.data.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchSingleLab: async ({ commit, dispatch }, id) => {
    try {
      await dispatch("errors/clear", [], {
        root: true,
      });
      commit("setSingleLab", null);

      let response = await axios.get("/labs/" + id);
      commit("setSingleLab", response.data.data);

      commit("setLabLogo", response.data.data.logo);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  addLab: async ({ commit, dispatch }, params) => {
    try {
      commit("setLabAddStatus", false);
      const labData = await axios.post("/labs", params);
      commit("setAddedLabData", labData.data.data);
      commit("setLabAddStatus", true);
    } catch (error) {
      commit("setLabAddStatus", false);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  editLab: async ({ commit, dispatch }, params) => {
    try {
      commit("setLabAddStatus", false);
      const labData = await axios.patch("/labs/" + params.id, params);
      commit("setAddedLabData", labData.data);
      commit("setLabAddStatus", labData.data);
    } catch (error) {
      commit("setLabAddStatus", false);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  deleteLab: async ({ commit }, params) => {
    try {
      commit("setLabDeleteStatus", false, {
        root: true,
      });
      await axios.delete(`/labs/${params.lab_id}`);
      commit("setLabDeleteStatus", true, {
        root: true,
      });
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setlabDeleteError", error.data.message);
      } else {
        commit("setlabDeleteError", "Error while deleting lab", {
          root: true,
        });
      }
    }
  },

  fetchTestPanels: async ({ commit }, params) => {
    try {
      commit("setTestPanels", []);
      let response = await axios.get("labs/" + params.lab_id + "/test-panels", {
        params,
      });
      commit("setTestPanels", response.data.data);
    } catch (err) {
      commit("setTestPanels", []);
    }
  },
  uploadLogo: async ({ commit }, params) => {
    try {
      commit("setLabLogoUpdateError", "");
      commit("setLogoAddStatus", false);
      let lab_id = params.lab_id;

      let data = new FormData();
      data.append("logo", params.logo);
      let response = await axios({
        method: "post",
        url: "labs/" + lab_id + "/logo",
        data: data,
      });

      commit("setLogoAddStatus", true);

      commit("setLabLogo", response.data.data.logo);
    } catch (error) {
      commit("setLogoAddStatus", false);

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        commit("setLabLogoUpdateError", error.response.data.message);
      } else {
        commit("setLabLogoUpdateError", "Error while updating logo");
      }
    }
  },

  fetchAllTimeZones: async ({ commit, dispatch }, params) => {
    try {
      let response = await axios.get("/us-timezones", {
        params,
      });
      commit("setAllTimeZones", response.data.data);
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchAllWorkSheets: async ({ commit }, params) => {
    let labId = params.lab_id
    delete params.lab_id
    try {
      commit("setWorkSheets", []);
      let response = await axios.get("lab/" + labId + "/work-sheets",
        { params },
      );
      commit("setWorkSheets", response.data.data);
    } catch (err) {
      commit("setWorkSheets", []);
    }
  },
  createWorkSheet: async ({ commit, dispatch }, { lab_id, config }) => {

    try {
      commit("setWorkSheetCreateStatus", false);
      await axios.post("lab/" + lab_id + "/work-sheets-create",
        config,
      );
      commit("setWorkSheetCreateStatus", true);
    } catch (err) {

      commit("setWorkSheetCreateStatus", false);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        });
      }
    }
  },
  fetchSingleTestConfig: async ({ commit, dispatch }, { lab_id, test_report_target_name_id }) => {
    try {
      commit("setWorkSheetDetails", []);
      let response = await axios.get("lab/" + lab_id + "/work-sheets/" + test_report_target_name_id
      );
      commit("setWorkSheetDetails", response.data.data);
    } catch (err) {
      commit("setWorkSheetDetails", []);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        });
      }
    }
  },
  updateTestConfigStatus: async ({ commit, dispatch }, { lab_id, test_report_id, params }) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setTestConfigStatus", false);
      await axios.put(`/lab/${lab_id}/work-sheets/${test_report_id}/update-status`, params);

      commit("setTestConfigStatus", true);
    } catch (err) {
      commit("setTestConfigStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
