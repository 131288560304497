import axios from "@/config/axios";

const initiateState = () => {
  return {
    allPayors: {},
  };
};
const state = initiateState();
const getters = {
  getAllPayors: (state) => state.allPayors,
};
const mutations = {
  setAllPayors(state, data) {
    state.allPayors = data;
  },

  reset: (state) => {
    const initial = initiateState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  fetchAllPayors: async ({ commit, dispatch }, params) => {
    try {
      commit("setAllPayors", { data: [] });
      const response = await axios.get("insurance-payors", { params });
      commit("setAllPayors", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
};
export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
};
