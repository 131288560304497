import axios from "@/config/axios"

const initialState = () => {
  return {
    pendingOrderDetails: {},
    verifyClientEmail: false,
    verifyClientOTP: false,
    pendingOrderLogs: [],
    addComment: false,
    clientLoginAccessToken: [],
    uploadAttachments: null,
    uploadedFilePath: null,
    notificationCount: {},
    notificaitonViewStatus: false,
    orderNotificationCounts: {},
    uploadedFileMetadata: null
  }
}

const state = initialState()
const getters = {
  getPendingOrderDetails: (state) => state.pendingOrderDetails,
  getVerifyClientEmailStatus: (state) => state.verifyClientEmail,
  getVerifyClientOTPStatus: (state) => state.verifyClientOTP,
  getPendingOrderLogs: (state) => state.pendingOrderLogs,
  getAddCommentStatus: (state) => state.addComment,
  getClientLoginAccessToken: (state) => state.clientLoginAccessToken,
  getUploadAttachmentsStatus: (state) => state.uploadAttachments,
  getUploadedFilePath: (state) => state.uploadedFilePath,
  getNotificationCount: (state) => state.notificationCount,
  getNotificationViewStatus: (state) => state.notificaitonViewStatus,
  getOrderNotificationCounts: (state) => state.orderNotificationCounts,
  getUploadedFileMetadata: (state) => state.uploadedFileMetadata
}

const mutations = {
  setPendingOrderDetails(state, data) {
    state.pendingOrderDetails = data
  },
  setVerifyClientEmail(state, data) {
    state.verifyClientEmail = data
  },
  setVerifyClientOTP(state, data) {
    state.verifyClientOTP = data
  },
  setPendingOrderLogs(state, data) {
    state.pendingOrderLogs = data
  },
  setAddComment(state, data) {
    state.addComment = data
  },
  setClientLoginAccessToken(state, data) {
    state.clientLoginAccessToken = data
  },
  setAuthenticatedUser: (state, user) => {
    state.user = user
  },
  setAuthenticationDetails: (state, tokens) => {
    state.authenticationDetails = tokens
  },
  setUploadAttachments: (state, data) => {
    state.uploadAttachments = data
  },
  setUploadedFilePath: (state, data) => {
    state.uploadedFilePath = data
  },
  setUploadedMetaData: (state, data) => {
    state.uploadedFileMetadata = data
  },
  setPendingNotificationCount: (state, data) => {
    state.notificationCount = data
  },
  setNotificationViewStatus: (state, data) => {
    state.notificaitonViewStatus = data
  },
  setOrderNotificationCounts: (state, data) => {
    state.orderNotificationCounts = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  fetchPendingOrderDetails: async ({ commit }, params) => {
    try {
      let orderId = params.order_id
      let response = await axios.get(`/orders/${orderId}/public`)
      commit("setPendingOrderDetails", response.data.data || {})
    } catch (error) {
      commit("setPendingOrderDetails", {})
    }
  },
  checkClientEmail: async ({ commit, dispatch }, params) => {
    try {
      commit("setVerifyClientEmail", false)
      await axios.post("/signin/guest", {
        email: params.email,
      })
      commit("setVerifyClientEmail", true)
    } catch (error) {
      commit("setVerifyClientEmail", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  verifyOTP: async ({ commit, dispatch }, params) => {
    try {
      commit("setVerifyClientOTP", false)
      commit("setClientLoginAccessToken", [])
      let response = await axios.post("/signin/guest/verify-otp", {
        email: params.email,
        otp: params.otp,
      })
      let userDetails = response.data.user_details
      let tokens = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      }
      commit("setVerifyClientOTP", true)
      commit("setClientLoginAccessToken", response.data.access_token)
      commit("auth/setAuthenticatedUser", userDetails, { root: true })
      commit("auth/setAuthenticationStatus", true, { root: true })

      commit("auth/setAuthenticationDetails", tokens, { root: true })
    } catch (error) {
      commit("setVerifyClientOTP", false)
      commit("setClientLoginAccessToken", [])
      commit("auth/setAuthenticatedUser", null, { root: true })

      commit("auth/setAuthenticationDetails", null, { root: true })
      commit("auth/setAuthenticationStatus", false, { root: true })

      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchPendingOrderLogs: async ({ commit }, params) => {
    try {
      let orderId = params.order_id
      let response = await axios.get(`/pending-rejection-order/logs/${orderId}`)
      commit("setPendingOrderLogs", response.data.data || [])
    } catch (error) {
      commit("setPendingOrderLogs", [])
    }
  },
  addOrderLogs: async ({ commit }, params) => {
    try {
      commit("setAddComment", false)
      let orderId = params.order_id
      await axios.post(`/pending-rejection-order/${orderId}/send-message`, {
        message: params.message,
        file_path: params.file_path,
        metadata: params.metadata
      })
      commit("setAddComment", true)
    } catch (error) {
      commit("setAddComment", false)
    }
  },
  uploadAttachment: async ({ commit }, params) => {
    try {
      commit("setUploadAttachments", null)
      commit("setUploadedFilePath", null)
      commit('setUploadedMetaData', null)
      let response = await axios.post(
        "pending-rejection-order/add-file",
        params
      )
      const data = response.data.data
      commit("setUploadAttachments", data.target_url)
      commit("setUploadedFilePath", data.file_path)
      commit('setUploadedMetaData', data.metadata)
    } catch (error) {
      console.log(error)
    }
  },
  fetchNotificationCount: async ({ commit }, params) => {
    try {

      let response = await axios.get(`/pending-rejection-order/notifications`, { params })
      commit("setPendingNotificationCount", response.data || {})
    } catch (error) {
      commit("setPendingNotificationCount", {})
    }
  },
  updateNotificationViewStatus: async ({ commit }, params) => {
    try {
      let orderId = params.orderId
      delete params.orderId
      commit("setNotificationViewStatus", false)

      await axios.patch(`/pending-rejection-order/${orderId}/view-status`)
      commit("setNotificationViewStatus", true)

    } catch (err) {
      commit("setNotificationViewStatus", false)

    }
  },
  fetchOrdersCount: async ({ commit }, params) => {
    try {

      commit("setOrderNotificationCounts", {})

      let response = await axios.post(`/pending-rejection-order/order-wise/notifications`, params)
      commit("setOrderNotificationCounts", response.data.data)

    } catch (err) {
      commit("setOrderNotificationCounts", {})

    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
