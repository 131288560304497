import axios from "@/config/axios"

const initialState = () => {
  return {
    reportFiles: {},
    singleReportFile: {},
    reportDeleteStatus: false,
    downloadUrl: null,
    fileUploadStatus: false,
    fileUploadErrors: null,
    patientDemographicSentStatus: null,
    singlePatientDemographicsStatus: null,
    sendFileToServerStatus: false,
    downloadStatus: false,
    syncReportStatus: null,
    singleSyncReportStatus: null,
    finalizeReportStatus: false,
    pdfDownloadUrl: null,
    reportViewUrl: null,
    reportDownload: null,
    generateReport: false,
  }
}

const state = initialState()
const getters = {
  getReportFiles: (state) => state.reportFiles,
  getSingleReportFile: (state) => state.singleReportFile,
  getReportDeleteStatus: (state) => state.reportDeleteStatus,
  getDownloadUrl: (state) => state.downloadUrl,
  getFileUploadStatus: (state) => state.fileUploadStatus,
  getFileUploadErrors: (state) => state.fileUploadErrors,
  getPatientDemographicSentStatus: (state) =>
    state.patientDemographicSentStatus,
  getSingleDemographicStatus: (state) => state.singlePatientDemographicsStatus,
  getSendFileToServerStatus: (state) => state.sendFileToServerStatus,
  getFileDownloadStatus: (state) => state.downloadStatus,
  getSyncReportStatus: (state) => state.syncReportStatus,
  getSingleSyncReportStatus: (state) => state.singleSyncReportStatus,
  getFinalizeReportStatus: (state) => state.finalizeReportStatus,
  getPdfFileDownloadUrl: (state) => state.pdfDownloadUrl,
  getViewReportUrl: (state) => state.reportViewUrl,
  getReportDownload: (state) => state.reportDownload,
  getGenerateReportStatus: (state) => state.generateReport,
}

const mutations = {
  setReportFiles(state, data) {
    state.reportFiles = data
  },
  setSingleReportFiles(state, data) {
    state.singleReportFile = data
  },
  setReportDeleteStatus(state, status) {
    state.reportDeleteStatus = status
  },
  setFileDownloadUrl(state, data) {
    state.downloadUrl = data
  },
  setFileDownloadStatus(state, status) {
    state.downloadStatus = status
  },
  setSingleFileUploadStatus(state, data) {
    state.fileUploadStatus = data
  },
  setSingleFileUploadErrors(state, data) {
    state.fileUploadErrors = data
  },
  setDemographicStatus(state, data) {
    state.patientDemographicSentStatus = data
  },
  setSingleDemographicStatus(state, data) {
    state.singlePatientDemographicsStatus = data
  },
  setSendFileToServerStatus(state, status) {
    state.sendFileToServerStatus = status
  },
  setSyncReportStatus(state, data) {
    state.syncReportStatus = data
  },
  setSingleSyncReportStatus(state, data) {
    state.singleSyncReportStatus = data
  },
  setFinalizeReportStatus(state, status) {
    state.finalizeReportStatus = status
  },
  setPdfFileDownloadUrl(state, data) {
    state.pdfDownloadUrl = data
  },
  setViewReportStatus(state, data) {
    state.reportViewUrl = data
  },
  setReportDownload(state, data) {
    state.reportDownload = data
  },
  setGenerateReport(state, data) {
    state.generateReport = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  fetchAll: async ({ commit }, params) => {
    try {
      const response = await axios.get("/pgx-report-files", { params })
      commit("setReportFiles", response.data)
    } catch (error) {
      commit("setReportFiles", null)
    }
  },
  fetchSingleReport: async ({ commit }, id) => {
    try {
      const response = await axios.get(`/pgx-report-files/${id}/reports`)
      commit("setSingleReportFiles", response.data)
    } catch (error) {
      commit("setSingleReportFiles", {})
    }
  },
  delete: async ({ commit, dispatch }, id) => {
    try {
      commit("setReportDeleteStatus", false)
      await axios.patch(`pgx-report-files/${id}`)
      commit("setReportDeleteStatus", true)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  sendFilesToServer: async ({ commit, dispatch }, id) => {
    try {
      commit("setSendFileToServerStatus", false)
      await axios.post(`pgx-report-files/${id}/send-results`)
      commit("setSendFileToServerStatus", true)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  download: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", [], { root: true })
      commit("setFileDownloadUrl", null)
      commit("setFileDownloadStatus", false)
      let response = await axios.post(
        `/pgx-report-files/${params.report_id}/download-files/${params.report_type}`
      )

      commit("setFileDownloadStatus", true)

      commit("setFileDownloadUrl", response.data.data)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  uploadSingleFile: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", null, { root: true })
      commit("setSingleFileUploadStatus", false)
      await axios.post("/pgx-report-files", params)
      commit("setSingleFileUploadStatus", true)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        })
      }
    }
  },
  sendDemographics: async ({ commit, dispatch }, id) => {
    try {
      commit("setDemographicStatus", false)
      let response = await axios.post(
        `/pgx-report-files/${id}/send-demographics`
      )
      commit("setDemographicStatus", response.data)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  sendSinglePatientDemographics: async ({ commit, dispatch }, params) => {
    try {
      commit("setSingleDemographicStatus", false)
      let response = await axios.post(
        `/pgx-report-files/${params.report_id}/send-demographics/${params.accession_id}`
      )
      commit("setSingleDemographicStatus", response.data)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  syncReport: async ({ commit, dispatch }, id) => {
    try {
      commit("setSyncReportStatus", false)
      let response = await axios.post(`/pgx-report-files/${id}/sync-reports`)
      commit("setSyncReportStatus", response.data)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  sendSingleSyncReport: async ({ commit, dispatch }, params) => {
    try {
      commit("setSingleSyncReportStatus", false)
      let response = await axios.post(
        `/pgx-report-files/${params.report_id}/sync-reports/${params.accession_id}`
      )
      commit("setSingleSyncReportStatus", response.data)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  finalizeReport: async ({ commit, dispatch }, params) => {
    try {
      commit("setFinalizeReportStatus", false)
      await axios.patch(
        `/pgx-report-files/${params.result_file_id}/reports/${params.report_id}/finalize-report`
      )
      commit("setFinalizeReportStatus", true)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  fetchFileDownloadUrl: async ({ commit, dispatch }, params) => {
    try {
      console.log(params)
      dispatch("errors/clear", [], { root: true })
      commit("setPdfFileDownloadUrl", null)
      commit("setFileDownloadStatus", false)
      let response = await axios.post(
        `/pgx-report-files/${params.report_id}/download-report/${params.accession_id}`
      )

      commit("setFileDownloadStatus", true)

      commit("setPdfFileDownloadUrl", response.data.data)
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  viewReport: async ({ commit, dispatch }, params) => {
    try {
      commit("setViewReportStatus", null)
      let response = await axios.get(
        `/pgx-report-files/${params.report_id}/view-report/${params.accession_id}`
      )
      commit("setViewReportStatus", response.data.data)
    } catch (err) {
      if (err.response) {
        dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  downloadTSIFile: async ({ commit }, params) => {
    try {
      let result_id = params.result_file_id
      let report_id = params.report_id
      commit("setReportDownload", null)
      let response = await axios.get(
        `/pgx-report-files/${result_id}/reports/${report_id}/preview/${params.report_type}`,
        params
      )
      commit("setReportDownload", response.data)
    } catch (error) {
      commit("setReportDownload", null)
    }
  },
  generaTSIteReport: async ({ commit, dispatch }, result_file_id) => {
    try {
      commit("setGenerateReport", false)
      await axios.post(`pgx-report-files/${result_file_id}/generate-report`)
      commit("setGenerateReport", true)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
