export const strict = false

import { createStore } from "vuex"

// Modules
import auth from "./modules/auth"
import createPersistedState from "vuex-persistedstate"
import config from "@/config/app"
import errors from "./modules/errors"
import help from "./modules/help"
import cases from "./modules/cases"
import batches from "./modules/batches"
import orders from "./modules/orders"
import emrOrders from "./modules/emrOrders"
import resultFiles from "./modules/resultFiles"
import hospitals from "./modules/hospitals"
import sendOuts from "./modules/sendOuts"
import QCChartResultFiles from "./modules/QCChartResultFiles"
import billingCases from "./modules/billingCases"
import physicians from "./modules/physicians"
import icd10Codes from "./modules/icd10Codes"
import userAccounts from "./modules/userAccounts"
import logs from "./modules/logs"
import updatePassword from "./modules/updatePassword"
import viewProgress from "./modules/viewProgress"
import profile from "./modules/profile"
import labs from "./modules/labs"
import labTests from "./modules/labTests"
import branches from "./modules/branches"
import payors from "./modules/payors"
import reportFiles from "./modules/reportFiles"
import respiratoryFileUpload from "./modules/respiratoryFileUpload"
import patients from "./modules/patients"
import qcCharts from "./modules/qcCharts"
import emailLogs from "./modules/emailLogs"
import pendingCases from "./modules/pendingCases"
import tsiReports from "./modules/tsiReports"
import labDocuments from "./modules/labDocuments"
import session from "./modules/session"
import testpanel from "./modules/testpanel"
import bloodConfig from "./modules/bloodConfig"
import bloodQcLevels from "./modules/bloodQcLevels"
import bloodResults from "./modules/bloodResults"
import bloodTestLevels from "./modules/bloodTestLevels"
import patientPortal from "./modules/patientPortal"
import logistics from "./modules/logistics"
import bulkAccessions from "./modules/bulkAccessions"
import patientReport from "./modules/patientReport"
import marketerStats from "./modules/marketerStats"
import testProfiles from "./modules/testProfiles"
import revenueStats from "./modules/revenueStats"
import phlebio from "./modules/phlebio"
import clientPendingOrders from "./modules/clientPendingOrders"
const moduleNames = [
  "auth",
  "errors",
  "profile",
  "session",
  "qcCharts",
  "orders",
  "patientPortal",
  "bloodTestLevels",
  "bloodResults",
  "bloodQcLevels",
  "bloodConfig",
  "batches",
  "help",
  "cases",
  "emrOrders",
  "resultFiles",
  "hospitals",
  "sendOuts",
  "QCChartResultFiles",
  "billingCases",
  "physicians",
  "icd10Codes",
  "userAccounts",
  "logs",
  "labs",
  "updatePassword",
  "viewProgress",
  "labTests",
  "branches",
  "payors",
  "reportFiles",
  "respiratoryFileUpload",
  "patients",
  "pendingCases",
  "emailLogs",
  "tsiReports",
  "labDocuments",
  "testpanel",
  "logistics",
  "bulkAccessions",
  "patientReport",
  "marketerStats",
  "testProfiles",
  "revenueStats",
  "phlebio",
  "clientPendingOrders",
]

const store = new createStore({
  modules: {
    auth,
    patientPortal,
    bloodTestLevels,
    bloodResults,
    bloodQcLevels,
    bloodConfig,
    batches,
    profile,
    qcCharts,
    errors,
    help,
    cases,
    orders,
    emrOrders,
    resultFiles,
    hospitals,
    sendOuts,
    QCChartResultFiles,
    billingCases,
    physicians,
    icd10Codes,
    userAccounts,
    logs,
    labs,
    updatePassword,
    viewProgress,
    labTests,
    branches,
    payors,
    reportFiles,
    respiratoryFileUpload,
    patients,
    pendingCases,
    emailLogs,
    session,
    tsiReports,
    labDocuments,
    testpanel,
    logistics,
    bulkAccessions,
    patientReport,
    marketerStats,
    testProfiles,
    revenueStats,
    phlebio,
    clientPendingOrders,
  },
  strict: false,
  plugins: [
    createPersistedState({
      key: config.APP_NAME + "_" + config.APP_VERSION,
      paths: moduleNames,
    }),
  ],
  actions: {
    globalReset({ commit }) {
      moduleNames.forEach((module) => {
        commit(`${module}/reset`)
      })
      localStorage.removeItem("componentShown")
      localStorage.removeItem("versionHandled")
    },
  },
})

export default store
