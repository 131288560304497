import AuthMiddleware from "@/middleware/auth";

const RevenueStats = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/revenueStats/RevenueStats"
  );
const FileUpload = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/revenueStats/FileUpload"
  );
const AllRevenueStats = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/revenueStats/AllRevenueStats"
  );
const IndividualRevenueStats = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/revenueStats/IndividualRevenueStats"
  );
const CombinedRevenueStats = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/revenueStats/CombinedRevenueStats"
  );
const ComparisonRevenueStats = () =>
  import(
    /* webpackChunkName: "marketers" */ "@/components/revenueStats/ComparisionRevenueStats"
  );
const RevenueRouter = [
  {
    path: "/combined-revenue-stats",
    name: "CombinedRevenueStats",
    component: CombinedRevenueStats,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "REVENUE STATS",
    },
    children: [
      {
        path: "/all-revenue-stats",
        name: "AllRevenueStats",
        component: AllRevenueStats,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "REVENUE STATS",
        },
      },
      {
        path: "/individual-revenue-stats",
        name: "IndividualRevenueStats",
        component: IndividualRevenueStats,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "REVENUE STATS",
        },
      },
      {
        path: "/comparison-revenue-stats",
        name: "ComparisonRevenueStats",
        component: ComparisonRevenueStats,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "ANALYSIS STATS",
        },
      },
      {
        path: "/revenue-stats",
        name: "RevenueStats",
        component: RevenueStats,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "FILE UPLOAD",
        },
      },
      {
        path: "/revenue-file-upload",
        name: "FileUpload",
        component: FileUpload,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "FILE UPLOAD",
        },
      },
    ],
  },
];

export { RevenueRouter };
