import axios from "@/config/axios";

const initialState = () => {
  return {
    allPhysicians: null,
    physicianSearchData: null,
    physicianAddStatus: false,
    addedPhysicianData: null,
    physicianValidationErrors: null,
    physicianStatusUpdate: false,
    physicianSignatureUpdate: false,
    singlePhysician: null,
    physicianDeleteStatus: null,
    allPhysicianErrors: null,
    physicianSearchValidationErrors: null,
    physicianValidationErrorsCode: null,
    pecosData: null,
  };
};

const state = initialState();

const getters = {
  getAllPhysicians: (state) => state.allPhysicians,
  getPhysicianSearchData: (state) => state.physicianSearchData,
  getPhysicianAddStatus: (state) => state.physicianAddStatus,
  getAddedPhysicianData: (state) => state.addedPhysicianData,
  getPhysicianValidationErrors: (state) => state.physicianValidationErrors,
  getPhysicianStatusUpdate: (state) => state.physicianStatusUpdate,
  getPhysicianSignatureUpdate: (state) => state.physicianSignatureUpdate,
  getSinglePhysician: (state) => state.singlePhysician,
  getPhysicianDeleteStatus: (state) => state.physicianDeleteStatus,
  getAllPhysicianErrors: (state) => state.allPhysicianErrors,
  getPhysicianSearchValidationErrors: (state) =>
    state.physicianSearchValidationErrors,
  getPhysicianValidationErrorsCode: (state) =>
    state.physicianValidationErrorsCode,
  getPecosData: (state) => state.pecosData
};

const mutations = {
  setAllPhysicians(state, data) {
    state.allPhysicians = data;
  },
  setPhysicianSearchData(state, data) {
    state.physicianSearchData = data;
  },
  setPhysicianAddStatus(state, status) {
    state.physicianAddStatus = status;
  },
  setAddedPhysicianData(state, data) {
    state.addedPhysicianData = data;
  },
  setPhysicianValidationErrors(state, data) {
    state.physicianValidationErrors = data;
  },
  setPhysicianStatusUpdate(state, status) {
    state.physicianStatusUpdate = status;
  },
  setPhysicianSignatureUpdate(state, status) {
    state.physicianSignatureUpdate = status;
  },
  setSinglePhysician(state, singlePhysician) {
    state.singlePhysician = singlePhysician;
  },

  setPhysicianDeleteStatus(state, data) {
    state.physicianDeleteStatus = data;
  },
  setAllPhysicianErrors(state, data) {
    state.allPhysicianErrors = data;
  },
  setPhysicianSearchValidationErrors(state, data) {
    state.physicianSearchValidationErrors = data;
  },
  setPhysicianValidationErrorCode(state, data) {
    state.physicianValidationErrorsCode = data;
  },
  setPecosData(state, data) {
    state.pecosData = data
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllPhysicians: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true });
      commit("setAllPhysicians", null);
      const response = await axios.get("/physicians", { params });

      commit("setAllPhysicians", response.data);
    } catch (error) {
      commit("setAllPhysicians", null);

      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },

  clearValidationErrors: ({ commit }) => {
    commit("setPhysicianValidationErrors", null);
    commit("setPhysicianSearchValidationErrors", null);
    commit("setPhysicianValidationErrorCode", null);
  },
  downloadPhysiciansData: async ({ commit }, params) => {
    try {
      const response = await axios.get("/physicians/download-as-csv", {
        params,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "physicians.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  },
  searchPhysicianByCode: async ({ commit, dispatch }, code) => {
    try {
      commit("setPhysicianSearchData", null);
      commit("setPhysicianSearchValidationErrors", null);
      let response = await axios.get(
        "/physicians/npi-registry?npi_number=" + code
      );
      commit("setPhysicianSearchData", response.data.data);
    } catch (error) {
      if (error.response) {
        commit("setPhysicianSearchValidationErrors", error.response.data);
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  editPhysician: async ({ commit, dispatch }, params) => {
    try {
      commit("setPhysicianValidationErrors", {});
      commit("setPhysicianAddStatus", false);
      const physicianData = await axios.put("/physicians/" + params.id, params);
      commit("setAddedPhysicianData", physicianData.data);
      commit("setPhysicianAddStatus", physicianData.data);
    } catch (error) {
      commit("setPhysicianAddStatus", false);
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setPhysicianValidationErrors", errorData);
      }
    }
  },

  addPhysician: async ({ commit, dispatch }, params) => {
    try {
      commit("setPhysicianValidationErrors", {});
      commit("setPhysicianAddStatus", false);
      commit("setPhysicianValidationErrorCode", null);
      const physicianData = await axios.post("/physicians", params);
      commit("setAddedPhysicianData", physicianData.data);
      commit("setPhysicianAddStatus", true);
    } catch (error) {
      if (error.response) {
        commit("setPhysicianValidationErrorCode", error.response);

        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  updatePhysicianStatus: async (
    { commit, dispatch },
    { physicianId, data }
  ) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setPhysicianStatusUpdate", false);
      await axios.patch(`physicians/${physicianId}/status`, data);
      commit("setPhysicianStatusUpdate", true);
    } catch (error) {
      commit("setPhysicianStatusUpdate", false);
      await dispatch("errors/errorResponse", error.response, { root: true });
    }
  },
  updatePhysicianSignature: async (
    { commit, dispatch },
    { physician_id, signature }
  ) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setPhysicianSignatureUpdate", false);
      await axios.patch(`physicians/${physician_id}/signature`, signature);
      commit("setPhysicianSignatureUpdate", true);
    } catch (error) {
      commit("setPhysicianSignatureUpdate", false);
      await dispatch("errors/errorResponse", error.response, { root: true });
    }
  },
  fetchSinglePhysician: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", null, { root: true });
      commit("setSinglePhysician", null);

      let response = await axios.get("/physicians/" + params.physician_id, {
        params,
      });

      commit("setSinglePhysician", response.data.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  deletePhysician: async ({ commit }, params) => {
    try {
      commit("setPhysicianDeleteStatus", false);
      await axios.delete(`/physicians/${params.physician_id}`);
      commit("setPhysicianDeleteStatus", true);
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setPhysicianDeleteError", error.data.message);
      } else {
        commit("setPhysicianDeleteError", "Error while deleting Physician");
      }
    }
  },
  checkPecosStatus: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", null, { root: true });
      commit("setPecosData", null);

      let response = await axios.get(`https://data.cms.gov/data-api/v1/dataset/2457ea29-fc82-48b0-86ec-3b0755de7515/data?filter[NPI]=${params}`);
      commit("setPecosData", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
