import axios from "@/config/axios";

const initialState = () => {
  return {
    allBulkAccessions: [],
    singleBulkAccession: {},
    bulkAccessionUploadFileStatus: false,
    startBulkAccessionStatus: null,
    bulkAccessionDeleteStatus: false,
    bulkAccessionDownload: null,
    bulkAccession: {},
  };
};

const state = initialState();

const getters = {
  getAllBulkAccessions: (state) => state.allBulkAccessions,
  getSingleBulkAccession: (state) => state.singleBulkAccession,
  getBulkAccessionUploadFileStatus: (state) =>
    state.bulkAccessionUploadFileStatus,
  getStartBulkAccessionStatus: (state) => state.startBulkAccessionStatus,
  getBulkAccessionDeleteStatus: (state) => state.bulkAccessionDeleteStatus,
  getBulkAccessionDownload: (state) => state.bulkAccessionDownload,
  getBulkAccession: (state) => state.bulkAccession,
};

const mutations = {
  setAllBulkAccessions(state, data) {
    state.allBulkAccessions = data;
  },
  setSingleBulkAccession(state, data) {
    state.singleBulkAccession = data;
  },
  setBulkAccessionUploadStatus(state, status) {
    state.bulkAccessionUploadFileStatus = status;
  },
  setBulkAccessionStartStatus(state, data) {
    state.startBulkAccessionStatus = data;
  },
  setBulkAccessionDeleteStatus(state, data) {
    state.bulkAccessionDeleteStatus = data;
  },
  setBulkAccessionDownload(state, data) {
    state.bulkAccessionDownload = data;
  },
  setBulkAccession(state, data) {
    state.bulkAccession = data;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  clearValidationErrors: ({ commit }) => {
    commit("hospitals/setAccessionValidationErrors", null, {
      root: true,
    });
  },
  fetchAllBulkAccessions: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true });

      const response = await axios.get("/bulk-accessions", { params });

      commit("setAllBulkAccessions", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  fetchBulkAccession: async ({ commit, dispatch }, params) => {
    try {
      let bulk_accession_id = params.bulk_accession_id;
      delete params.bulk_accession_id;
      commit("setBulkAccession", null);
      commit("bulkAccessions/setSingleBulkAccession", null, {
        root: true,
      });
      let response = await axios.get(
        "/bulk-accessions/" + bulk_accession_id + "/logs",
        {
          params,
        }
      );
      commit("bulkAccessions/setSingleBulkAccession", response.data, {
        root: true,
      });
      commit("bulkAccessions/setBulkAccession", response.data.data, {
        root: true,
      });
    } catch (err) {
      commit("bulkAccessions/setSingleBulkAccession", null, {
        root: true,
      });
    }
  },
  saveBulkAccessionFiles: async ({ commit, dispatch }, params) => {
    try {
      commit("setBulkAccessionUploadStatus", false);
      await axios.post("/bulk-accessions", params);
      commit("setBulkAccessionUploadStatus", true);
    } catch (err) {
      commit("setBulkAccessionUploadStatus", false);
      if (err.response) {
        dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  startBulkAccession: async ({ commit, dispatch }, params) => {
    try {
      let bulk_accession_id = params.bulk_accession_id;
      delete params.bulk_accession_id;
      commit("setBulkAccessionStartStatus", false);
      commit("errors/clear", null, { root: true });
      await axios.post(
        "/bulk-accessions/" + bulk_accession_id + "/start-accession"
      );
      commit("setBulkAccessionStartStatus", true);
    } catch (err) {
      commit("setBulkAccessionStartStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  downloadBulkAccession: async ({ commit, dispatch }, params) => {
    try {
      let bulk_accession_id = params.bulk_accession_id;
      delete params.bulk_accession_id;
      await dispatch("errors/clear", null, { root: true });
      commit("bulkAccessions/setBulkAccessionDownload", null, {
        root: true,
      });
      const response = await axios.get(
        "/bulk-accessions/" + bulk_accession_id + "/download-url",
        params
      );
      commit("bulkAccessions/setBulkAccessionDownload", response.data.data, {
        root: true,
      });
    } catch (error) {
      commit("bulkAccessions/setBulkAccessionDownload", null, {
        root: true,
      });
    }
  },
  deleteBulkAccession: async (context, params) => {
    try {
      let bulk_accession_id = params.bulk_accession_id;
      delete params.bulk_accession_id;
      context.commit("bulkAccessions/setBulkAccessionDeleteStatus", false, {
        root: true,
      });
      await axios.delete("/bulk-accessions/" + bulk_accession_id);
      context.commit("bulkAccessions/setBulkAccessionDeleteStatus", true, {
        root: true,
      });
    } catch (error) {
      context.commit("bulkAccessions/setBulkAccessionDeleteStatus", false, {
        root: true,
      });
    }
  },
  errorResponse: async (context, errorResponse) => {
    let errorStatusCode = errorResponse.status;
    let errorData = {};

    switch (errorStatusCode) {
      case 422:
        var errors = errorResponse.data.errors;
        errors.details.forEach((detail) => {
          if (detail.key == "patient_info") {
            if (!errorData[detail.path]) {
              errorData[detail.path] = detail.message;
            }
          } else {
            if (!errorData[detail.key]) {
              errorData[detail.key] = detail.message;
            }
          }
        });
        break;
      case 401:
      case 403:
        errorData["critical_error"] = errorResponse.data.message;
        break;

      default:
        errorData["critical_error"] = errorResponse.data.message;
        break;
    }
    return errorData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
