import axios from "@/config/axios"

const initialState = () => {
    return {
        allTestProfiles: null,
        testProfileAddStatus: false,
        singleTestProfile: null,
        testProfileUpdateStatus: false,
        testProfileDeleteStatus: false,
        testProfileDeleteError: null,
        testProfileChangeStatus: null,
        caseTypeTestProfiles: null
    }
}

const state = initialState()

const getters = {

    getAllTestProfiles: (state) => state.allTestProfiles,
    getTestProfilesAddStatus: (state) => state.testProfileAddStatus,
    getSingleTestProfile: (state) => state.singleTestProfile,
    getTestProfilesUpdateStatus: (state) => state.testProfileUpdateStatus,
    getTestProfileDeleteStatus: (state) => state.testProfileDeleteStatus,
    getTestProfileDeleteError: (state) => state.testProfileDeleteError,
    getTestProfileStatusUpdate: (state) => state.testProfileChangeStatus,
    getCaseTypeTestProfiles: (state) => state.caseTypeTestProfiles

}

const mutations = {

    setAllTestProfiles(state, profiles) {
        state.allTestProfiles = profiles
    },
    setCaseTypeTestProfiles(state, profile) {
        state.caseTypeTestProfiles = profile
    },
    setProfileAddStatus(state, status) {
        state.testProfileAddStatus = status
    },
    setSingleTestProfiles(state, data) {
        state.singleTestProfile = data
    },
    setProfileUpdateStatus(state, status) {
        state.testProfileUpdateStatus = status
    },
    setProfileDeleteError(state, data) {
        state.testProfileDeleteError = data;
    },
    setTestProfileDeleteStatus(state, data) {
        state.testProfileDeleteStatus = data;
    },
    setTestProfileStatusUpdate(state, status) {
        state.testProfileChangeStatus = status
    },
    reset: (state) => {
        const initial = initialState()
        Object.keys(initial).forEach((key) => {
            state[key] = initial[key]
        })
    },
}

const actions = {

    fetchTestProfiles: async ({ commit, dispatch }, params) => {
        try {
            await dispatch("errors/clear", [], { root: true });

            let response = await axios.get(
                '/hospital-test-profiles', { params }
            )
            commit("setAllTestProfiles", response.data)

        } catch (error) {
            if (error.response) {
                dispatch("errors/errorResponse", error.response, {
                    root: true,
                })
            }
        }
    },
    fetchCaseTypeTestProfile: async ({ commit, dispatch }, { case_type }) => {
        try {
            await dispatch("errors/clear", [], { root: true });

            let response = await axios.get(
                `/hospital-test-profiles/case-types/${case_type}/tests`
            )
            commit("setCaseTypeTestProfiles", response.data)

        } catch (error) {
            if (error.response) {
                dispatch("errors/errorResponse", error.response, {
                    root: true,
                })
            }
        }
    },
    addTestProfiles: async ({ commit, dispatch }, params) => {
        try {
            dispatch("errors/clear", [], { root: true })
            commit("setProfileAddStatus", false)
            await axios.post("/hospital-test-profiles", params)
            commit("setProfileAddStatus", true,)
        } catch (error) {
            commit("setProfileAddStatus", false,)
            if (error.response) {
                dispatch("errors/errorResponse", error.response, {
                    root: true,
                });
            }
        }
    },
    updateTestProfiles: async ({ commit, dispatch }, params) => {
        try {
            let profileId = params.profileId;
            delete params.profileId
            dispatch("errors/clear", [], { root: true })
            commit("setProfileUpdateStatus", false)
            await axios.put("/hospital-test-profiles/" + profileId, params)
            commit("setProfileUpdateStatus", true,)
        } catch (error) {
            commit("setProfileUpdateStatus", false,)
            if (error.response) {
                dispatch("errors/errorResponse", error.response, {
                    root: true,
                });
            }
        }
    },
    fetchTestProfilesById: async ({ commit, dispatch }, { profileId }) => {
        try {
            await dispatch("errors/clear", [], { root: true });
            commit("setSingleTestProfiles", null)

            let response = await axios.get(
                '/hospital-test-profiles/' + profileId
            )
            commit("setSingleTestProfiles", response.data)

        } catch (error) {
            if (error.response) {
                dispatch("errors/errorResponse", error.response, {
                    root: true,
                })
            }
        }
    },
    deleteTestProfile: async ({ commit, dispatch }, { profileId }) => {
        try {
            commit("setTestProfileDeleteStatus", false);
            await axios.delete(`/hospital-test-profiles/${profileId}`);
            commit("setTestProfileDeleteStatus", true);
        } catch (error) {
            if (error && error.data && error.data.message) {
                commit("setProfileDeleteError", error.data.message);
            } else {
                commit("setProfileDeleteError", "Error while deleting test profile");
            }
        }
    },
    changeTestProfileStatus: async ({ commit, dispatch }, { status, profileId }) => {
        try {
            commit("setTestProfileStatusUpdate", null)

            let response = await axios.patch(
                `/hospital-test-profiles/${profileId}/status/${status}`
            )
            commit("setTestProfileStatusUpdate", response.data,)
        } catch (error) {
            if (error.response) {
                console.log(error.response)
            }
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
