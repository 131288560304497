import axios from "@/config/axios"
import * as PrivateAxios from "axios"
const initialState = () => {
  return {
    allSendOuts: [],
    allReceivedSendOuts: [],
    sendOutTestInformationData: false,
    sendOutInformation: {},
    receivedSendOutInformation: {},
    sendOutInformationValidationErrors: {},
    sendOutInformationSaveStatus: false,
    sendOutDeleteStatus: false,
    sendOutDeleteError: null,
    showMedication: false,
    downloadSendoutStatus: false,
    reportSyncStatus: false,
    orderSentStatus: false,
  }
}

const state = initialState()

const getters = {
  getAllReceivedSendOuts: (state) => state.allReceivedSendOuts,
  getSendOutInformationValidationErrors: (state) =>
    state.sendOutInformationValidationErrors,
  getSendOutInformationSaveStatus: (state) =>
    state.sendOutInformationSaveStatus,
  getAllSendOuts: (state) => state.allSendOuts,
  getAllSendOutErrors: (state) => state.allSendOutErrors,
  getSendOutTestInformationData: (state) => state.sendOutTestInformationData,
  getSendOutInformation: (state) => state.sendOutInformation,
  getReceivedSendOutInformation: (state) => state.receivedSendOutInformation,
  getSendOutDeleteStatus: (state) => state.sendOutDeleteStatus,
  getSendOutDeleteError: (state) => state.sendOutDeleteError,
  getShowMedication: (state) => state.showMedication,
  getSendoutsDownloadStatus: (state) => state.downloadSendoutStatus,
  getReportSyncStatus: (state) => state.reportSyncStatus,
  getOrderSentStatus: (state) => state.orderSentStatus,
}

const mutations = {
  setAllReceivedSendOuts(state, data) {
    state.allReceivedSendOuts = data
  },
  setSendOutInformationValidationErrors(state, data) {
    state.sendOutInformationValidationErrors = data
  },
  setSendOutInformationSaveStatus(state, data) {
    state.sendOutInformationSaveStatus = data
  },
  setAllSendOuts(state, data) {
    state.allSendOuts = data
  },
  setAllSendOutErrors(state, data) {
    state.allSendOutErrors = data
  },
  setSendOutInformation(state, data) {
    state.sendOutInformation = data
  },
  setReceivedSendOutInformation(state, data) {
    state.receivedSendOutInformation = data
  },
  setSendOutDeleteError(state, data) {
    state.sendOutDeleteError = data
  },
  setSendOutDeleteStatus(state, data) {
    state.sendOutDeleteStatus = data
  },
  setShowMedication(state, data) {
    state.showMedication = data
  },
  setDownloadCaseDataStatus(state, status) {
    state.downloadSendoutStatus = status
  },
  setReportSyncStatus(state, status) {
    state.reportSyncStatus = status
  },
  setOrderSentStatus(state, status) {
    state.orderSentStatus = status
  },
  reset(state) {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  fetchReceivedSendOuts: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", null, { root: true })

      let response = []
      if (params) {
        response = await axios.get("/sendouts-received", { params })
      } else {
        response = await axios.get("/sendouts-received")
      }
      commit("setAllReceivedSendOuts", response.data)
    } catch (err) {
      commit("setAllSendOutErrors", true)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  getSendOuts: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", null, { root: true })

      let response = []
      if (params) {
        response = await axios.get("/sendouts", { params })
      } else {
        response = await axios.get("/sendouts/")
      }
      commit("setAllSendOuts", response.data)
    } catch (err) {
      commit("setAllSendOutErrors", true)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  createNewSendOut: async ({ commit, dispatch }, params) => {
    try {
      commit("setSendOutInformationValidationErrors", {})
      commit("setSendOutInformationSaveStatus", false)
      const response = await axios.post("/case-sendout", params)
      commit("setSendOutInformation", response.data.data)
      commit("setSendOutInformationSaveStatus", true)
      if (
        params.case_types.indexOf("TOXICOLOGY") > -1 ||
        params.case_types.indexOf("PGX_TEST") > -1
      ) {
        commit("setShowMedication", true)
      } else {
        commit("setShowMedication", false)
      }
    } catch (error) {
      commit("setSendOutInformationSaveStatus", false)
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
        commit("setSendOutInformationValidationErrors", errorData)
      }
    }
  },
  updateSendOut: async ({ commit, dispatch }, params) => {
    try {
      commit("setSendOutInformationValidationErrors", {})
      commit("setSendOutInformationSaveStatus", false)
      const response = await axios.put(
        `/sendouts/${params.send_out_id}`,
        params
      )
      commit("setSendOutInformation", response.data.data)
      commit("setSendOutInformationSaveStatus", true)
      if (
        params.case_types.indexOf("TOXICOLOGY") > -1 ||
        params.case_types.indexOf("PGX_TEST") > -1
      ) {
        commit("setShowMedication", true)
      } else {
        commit("setShowMedication", false)
      }
    } catch (error) {
      commit("setSendOutInformationSaveStatus", false)
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
        commit("setSendOutInformationValidationErrors", errorData)
      }
    }
  },
  fetchSendOutInformation: async ({ commit, dispatch }, params) => {
    try {
      commit("setSendOutInformation", {})
      const response = await axios.get("/sendouts/" + params.send_out_id)
      commit("setSendOutInformation", response.data.data)
      if (
        response.data.data.case_types.indexOf("TOXICOLOGY") > -1 ||
        response.data.data.case_types.indexOf("PGX_TEST") > -1
      ) {
        commit("setShowMedication", true)
      } else {
        commit("setShowMedication", false)
      }
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setSendOutInformationError", error.data.message)
      } else {
        commit("setSendOutInformationError", "Error while getting the data")
      }
    }
  },
  fetchReceivedSendOutInformation: async ({ commit, dispatch }, params) => {
    try {
      const response = await axios.get(
        "/sendouts-received/" + params.send_out_id
      )
      commit("setReceivedSendOutInformation", response.data.data)
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setSendOutInformationError", error.data.message)
      } else {
        context.commit(
          "setSendOutInformationError",
          "Error while getting the data"
        )
      }
    }
  },
  deleteSendOut: async ({ commit, dispatch }, params) => {
    try {
      commit("setSendOutDeleteStatus", false)
      await axios.delete(`/sendouts/${params.send_out_id}`)
      commit("setSendOutDeleteStatus", true)
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setSendOutDeleteError", error.data.message)
      } else {
        commit("setSendOutDeleteError", "Error while deleting Send Out")
      }
    }
  },
  downloadReceivedSendOutData: async (context, params) => {
    try {
      const response = await axios.get("/sendouts-received/download-as-csv", {
        params,
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "send_outs.csv")
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.log(error)
    }
  },
  downloadSendOutData: async ({ commit }, params) => {
    try {
      commit("setDownloadCaseDataStatus", false)
      const response = await axios.get("/sendouts/download-as-csv", { params })
      commit("setDownloadCaseDataStatus", true)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "send_outs.csv") //or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      commit("setDownloadCaseDataStatus", false)
      console.log(error)
    }
  },
  syncNfcReports: async ({ commit }, params) => {
    try {
      commit("setReportSyncStatus", false)
      await axios.post(`/ncf-reports/${params.sendout_id}/sync-reports`)
      commit("setReportSyncStatus", true)
    } catch (error) {
      commit("setReportSyncStatus", false)
      console.log(error)
    }
  },
  sendNcfOrder: async ({ commit, dispatch }, params) => {
    try {
      commit("setOrderSentStatus", false)
      await axios.post(`/ncf-reports/${params.sendout_id}/send-order`)
      commit("setOrderSentStatus", true)
    } catch (error) {
      commit("setOrderSentStatus", false)
      console.log(error)
    }
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
