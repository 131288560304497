import axios from "axios"
import moment from "moment-timezone"
const initialState = () => {
  return {
    revenueStatsFileUpload: false,
    revenueStatsProcessFile: false,
    allRevenueStats: [],
    managerWiseMarketers: [],
    individualRevenueStats: [],
  }
}

const state = initialState()
const getters = {
  getRevenueStatsFileUploadStatus: (state) => state.revenueStatsFileUpload,
  getRevenueStatsProcessFileStatus: (state) => state.revenueStatsProcessFile,
  getAllRevenueStats: (state) => state.allRevenueStats,
  getManagerWiseMarketers: (state) => state.managerWiseMarketers,
  getIndividualRevenueStats: (state) => state.individualRevenueStats,
}

const mutations = {
  setRevenueStatsFileUpload(state, data) {
    state.revenueStatsFileUpload = data
  },
  setRevenueStatsProcessFile(state, data) {
    state.revenueStatsProcessFile = data
  },
  setAllRevenueStats(state, data) {
    state.allRevenueStats = data
  },
  setManagerWiseMarketers(state, data) {
    state.managerWiseMarketers = data
  },
  setIndividualRevenueStats(state, data) {
    state.individualRevenueStats = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  uploadRevenueStatsFileUpload: async ({ commit }, params) => {
    try {
      commit("setRevenueStatsFileUpload", false)
      await axios.post(
        "https://dlw-api-analytics.labsquire.com/v1.0/revenue/upload",
        params
      )
      commit("setRevenueStatsFileUpload", true)
    } catch (error) {
      commit("setRevenueStatsFileUpload", false)
    }
  },
  revenueStatsProcessFile: async ({ commit }, params) => {
    try {
      commit("setRevenueStatsProcessFile", false)
      await axios.post(
        "https://dlw-api-analytics.labsquire.com/v1.0/revenue/process"
      )
      commit("setRevenueStatsProcessFile", true)
    } catch (error) {
      commit("setRevenueStatsProcessFile", false)
    }
  },
  fetchRevenueStats: async ({ commit }, params) => {
    try {
      commit("setAllRevenueStats", [])
      let response = await axios.post(
        "https://dlw-api-analytics.labsquire.com/v1.0/revenue/combined",
        params
      )
      commit("setAllRevenueStats", response.data)
    } catch (error) {
      commit("setAllRevenueStats", [])
    }
  },
  fetchManagerWiseMarketersData: async ({ commit, dispatch }, managerId) => {
    try {
      const response = await axios.get(
        `https://dlw-api-analytics.labsquire.com/v1.0/lis/manager/${managerId}`
      )
      commit("setManagerWiseMarketers", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchIndividualRevenueStats: async ({ commit }, params) => {
    try {
      commit("setIndividualRevenueStats", [])
      let response = await axios.post(
        "https://dlw-api-analytics.labsquire.com/v1.0/revenue/individual",
        params
      )
      commit("setIndividualRevenueStats", response.data)
    } catch (error) {
      commit("setIndividualRevenueStats", [])
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
