import axios from "@/config/axios";

const initialState = () => {
  return {
    reportFiles: null,
    reportFile: {},
    reportCreateStatus: false,
    reportUpdateStatus: false,
    reportDeleteStatus: false,
    sendDropCSVStatus: false,
    syncReportStatus: false,
    finalizeReportStatus: false,
    report: null,
  };
};

const state = initialState();
const getters = {
  getReportFiles: (state) => state.reportFiles,
  getReportFileById: (state) => state.reportFile,
  getReportCreateStatus: (state) => state.reportCreateStatus,
  getReportUpdateStatus: (state) => state.reportUpdateStatus,
  getReportDeleteStatus: (state) => state.reportDeleteStatus,
  getSendDropCSVStatus: (state) => state.sendDropCSVStatus,
  getSyncReportStatus: (state) => state.syncReportStatus,
  getFinalizeReportStatus: (state) => state.finalizeReportStatus,
  getReport: (state) => state.report,
};

const mutations = {
  setReportFiles(state, data) {
    state.reportFiles = data;
  },
  setReportFile(state, data) {
    state.reportFile = data;
  },
  setReportCreateStatus(state, status) {
    state.reportCreateStatus = status;
  },
  setReportUpdateStatus(state, status) {
    state.reportUpdateStatus = status;
  },
  setReportDeleteStatus(state, status) {
    state.reportDeleteStatus = status;
  },
  setSendDropCSVStatus(state, status) {
    state.sendDropCSVStatus = status;
  },
  setSyncReportStatus(state, status) {
    state.syncReportStatus = status;
  },
  setFinalizeReportStatus(state, status) {
    state.finalizeReportStatus = status;
  },
  setReport(state, report) {
    state.report = report;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAll: async ({ commit }, params) => {
    try {
      const response = await axios.get("/cardiac-report-files", { params });
      commit("setReportFiles", response.data);
    } catch (error) {
      commit("setReportFiles", null);
    }
  },
  show: async ({ commit }, id) => {
    try {
      const response = await axios.get(`cardiac-report-files/${id}`);
      commit("setReportFile", response.data.data);
    } catch (err) {
      commit("setReportFile", {});
    }
  },
  create: async ({ commit, dispatch }, params) => {
    try {
      commit("setReportCreateStatus", false);
      await axios.post("cardiac-report-files", params);
      commit("setReportCreateStatus", true);
    } catch (err) {
      commit("setReportCreateStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  update: async ({ commit, dispatch }, { id, params }) => {
    try {
      commit("setReportUpdateStatus", false);
      await axios.patch(`cardiac-report-files/${id}`, params);
      commit("setReportUpdateStatus", true);
    } catch (err) {
      commit("setReportUpdateStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  delete: async ({ commit }, id) => {
    try {
      commit("setReportDeleteStatus", false);
      await axios.delete(`cardiac-report-files/${id}`);
      commit("setReportDeleteStatus", true);
    } catch (err) {
      commit("setReportDeleteStatus", false);
    }
  },
  download: async ({ commit, dispatch }, { id, name }) => {
    try {
      dispatch("errors/clear", [], { root: true });
      commit("batches/setBatchDownloadStatus", false, { root: true });

      let response = await axios.get(
        `/cardiac-report-files/${id}/download-drop-csv`
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();

      commit("batches/setBatchDownloadStatus", true, { root: true });
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  sendDropCSV: async ({ commit }, id) => {
    try {
      commit("setSendDropCSVStatus", false);
      await axios.post(`/cardiac-report-files/${id}/send-drop-csv`);
      commit("setSendDropCSVStatus", true);
    } catch (err) {
      commit("setSendDropCSVStatus", false);
    }
  },
  syncReport: async ({ commit }, id) => {
    try {
      commit("setSyncReportStatus", false);
      await axios.post(`/cardiac-report-files/${id}/sync-reports`);
      commit("setSyncReportStatus", true);
    } catch (err) {
      commit("setSyncReportStatus", false);
    }
  },
  syncCaseForReportChanges: async ({ commit }, { id, accession_id }) => {
    try {
      commit("setSyncReportStatus", false);
      await axios.post(
        `/cardiac-report-files/${id}/sync-reports/${accession_id}`
      );
      commit("setSyncReportStatus", true);
    } catch (err) {
      commit("setSyncReportStatus", false);
    }
  },
  finalizeCaseReport: async ({ commit }, { id, accession_id }) => {
    try {
      commit("setFinalizeReportStatus", false);
      await axios.post(
        `/cardiac-report-files/${id}/sync-reports/${accession_id}/finalize-report`
      );
      commit("setFinalizeReportStatus", true);
    } catch (err) {
      commit("setFinalizeReportStatus", false);
    }
  },
  fetchReportById: async ({ commit, dispatch }, { id, caseId }) => {
    try {
      commit("setReport", null);
      commit("errors/clear", null, { root: true });
      const response = await axios.get(
        `result-files/supplementary-report/${caseId}/report/${id}`
      );
      commit("setReport", response.data.data);
    } catch (err) {
      commit("setReport", null);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
