import axios from "@/config/axios";

const initialState = () => {
  return {
    viewprogress: [],
  };
};

const state = initialState();

const getters = {
  getAllViewProgress: (state) => state.viewprogress,
};

const mutations = {
  setAllViewProgress(state, viewprogress) {
    state.viewprogress = viewprogress;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllViewProgress: async ({ commit, dispatch }, { id, params }) => {
    try {
      await dispatch("errors/clear", [], { root: true });

      const response = await axios.get(
        `/result-files-covid/${id}/sample-names`,
        { params }
      );

      commit("setAllViewProgress", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  errorResponse: async (context, errorResponse) => {
    let errorStatusCode = errorResponse.status;
    let errorData = {};

    switch (errorStatusCode) {
      case 422:
        var errors = errorResponse.data.errors;
        errors.details.forEach((detail) => {
          if (!errorData[detail.key]) {
            errorData[detail.key] = detail.message;
          }
        });
        break;
      case 401:
      case 403:
        errorData["critical_error"] = errorResponse.data.message;
        break;

      default:
        errorData["critical_error"] = errorResponse.data.message;
        break;
    }
    return errorData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
