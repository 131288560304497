import AuthMiddleware from "@/middleware/auth";
const AllBillingCase = () =>
  import(
    /* webpackChunkName: "billingcases" */ "@/views/billingCases/AllBillingCase"
  );
const ActiveBillingCase = () =>
  import(
    /* webpackChunkName: "billingcases" */ "@/views/billingCases/ActiveBillingCase"
  );
const PendingBillingCase = () =>
  import(
    /* webpackChunkName: "billingcases" */ "@/views/billingCases/PendingBillingCase"
  );
const CompleteBillingCase = () =>
  import(
    /* webpackChunkName: "billingcases" */ "@/views/billingCases/CompleteBillingCase"
  );
const AddBillingCase = () =>
  import(
    /* webpackChunkName: "billingcases" */ "@/views/billingCases/AddBillingCase"
  );

const billingCasesRouter = [
  {
    path: "/billing-cases",
    name: "AllBillingCase",
    component: AllBillingCase,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BILLING CASES",
    },
  },

  {
    path: "/billing-cases/active",
    name: "ActiveBillingCase",
    component: ActiveBillingCase,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ALL BILLING CASES",
    },
  },
  {
    path: "/billing-cases/pending",
    name: "PendingBillingCase",
    component: PendingBillingCase,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ALL BILLING CASES",
    },
  },
  {
    path: "/billing-cases/finalized",
    name: "CompleteBillingCase",
    component: CompleteBillingCase,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ALL BILLING CASES",
    },
  },
  {
    path: "/add-billingCases",
    name: "AddBillingCase",
    component: AddBillingCase,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BILLING CASES",
    },
  },
];

export { billingCasesRouter };
