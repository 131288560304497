import axios from "@/config/axios";

const initialState = () => {
  return {
    allStates: [],
    stateByCode: {
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FM: "Federated States Of Micronesia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MH: "Marshall Islands",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      MP: "Northern Mariana Islands",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PW: "Palau",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VI: "Virgin Islands",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    },

    allCities: [],
    citiesLoading: false,
  };
};

const state = initialState();
const getters = {
  getAllStates: (state) => state.allStates,
  getAllCities: (state) => state.allCities,
  getCitiesLoading: (state) => state.citiesLoading,
};
const mutations = {
  setAllStates(state, data) {
    state.allStates = data;
  },
  setAllCities(state, data) {
    state.allCities = data;
  },

  setCitiesLoading(state, data) {
    state.citiesLoading = data;
  },

  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllStates: async ({ commit }) => {
    try {
      let response = await axios.get("/states");
      commit("setAllStates", response.data.data || []);
    } catch (error) {
      commit("setAllStates", []);
    }
  },

  fetchAllCities: async ({ commit }, params) => {
    try {
      if (params.loading) {
        commit("setCitiesLoading", true);
      }
      let response = await axios.get("/states/cities", { params });
      if (params.loading) {
        commit("setCitiesLoading", false);
      }
      commit(
        "setAllCities",
        response.data && response.data.data && response.data.data[0]
          ? response.data.data[0].cities || []
          : []
      );
    } catch (error) {
      commit("setCitiesLoading", false);
      commit("setAllCities", []);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
