import axios from "@/config/axios";

const initialState = () => {
  return {
    orderCreationData: {},
    otpValidation: false,
    patientSignInStatus: false,
    orderCreationStatus: {},
    orderDetails: false,
    acceptCookies: false,
    patientOrderDetails: {},

    patientInfo: {},
    insuranceInfo: {},
    additionalInfo: {},

    orderByID: null,
    ordersByMobile: null,

    authenticationStatus: null,
    cityLoading: false,
    allCity: [],
    resultOtpValidation: false,

    testReportURL: "",
    reportGenerateStatus: false,
  };
};

const state = initialState();

const getters = {
  getAcceptingCookies: (state) => state.acceptCookies,
  getOtpValidationStatus: (state) => state.otpValidation,
  getPatientSignInStatus: (state) => state.patientSignInStatus,
  getPatientInformation: (state) => state.orderCreationStatus,
  getResultOtpValidationStatus: (state) => state.resultOtpValidation,
  getOrderDetails: (state) => state.orderDetails,
  getPatientOrderDetails: (state) => state.patientOrderDetails,

  getPatientInfo: (state) => state.patientInfo,
  getInsuranceInfo: (state) => state.insuranceInfo,

  getOrderByID: (state) => state.orderByID,
  getOrdersByMobile: (state) => state.ordersByMobile,

  getAuthenticationStatus: (state) => state.authenticationStatus,
  getAllCity: (state) => state.allCity,
  getCityLoading: (state) => state.cityLoading,

  getReportGenerateStatus: (state) => state.reportGenerateStatus,
  getTestReportURL: (state) => state.testReportURL,
};

const mutations = {
  setAcceptingCookies(state, status) {
    state.acceptCookies = status;
  },

  setOrderCreationData(state, data) {
    state.orderCreationData = data;
  },
  setOrderCreationStatus(state, data) {
    state.orderCreationStatus = data;
  },
  setOtpValidationStatus(state, data) {
    state.otpValidation = data;
  },

  setPatientSignInStatus(state, data) {
    state.patientSignInStatus = data;
  },

  setOrderDetails(state, data) {
    state.orderDetails = data;
  },

  setPatientOrderDetails(state, data) {
    state.patientOrderDetails = data;
  },

  setAuthenticationStatus(state, data) {
    state.authenticationStatus = data;
  },

  setPatientInfo(state, data) {
    state.patientInfo = data;
  },
  setReportGenerateStatus(state, data) {
    state.reportGenerateStatus = data;
  },
  setTestReportURL(state, data) {
    state.testReportURL = data;
  },

  setOrderByID(state, order) {
    state.orderByID = order;
  },
  setOrdersByMobile(state, orders) {
    state.ordersByMobile = orders;
  },
  setCityLoading(state, data) {
    state.cityLoading = data;
  },
  setAllCity(state, data) {
    state.allCity = data;
  },
  clear: (state) => {
    state.patientInfo = {};
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  orderAtest: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setOrderCreationStatus", null);

      const response = await axios.post("/patient-user-signup", params);

      commit("setOrderCreationStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
      console.log(err);
    }
  },

  verifyOTP: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setOtpValidationStatus", null);
      const response = await axios.post("/patient-user-validation", params);
      commit("setOtpValidationStatus", response.data);
      commit("setAuthenticationStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
      commit("setAuthenticationStatus", null);
      console.log(err);
    }
  },
  resendOTP: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setOtpValidationStatus", null);
      const response = await axios.post("/resend-otp", params);
      commit("setOtpValidationStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
      console.log(err);
    }
  },

  patientSignIn: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setPatientSignInStatus", null);
      const response = await axios.post("/patient-user-signin", params);
      commit("setPatientSignInStatus", response.data);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        });
      }
      console.log(err);
    }
  },
  fetchOrderDetails: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setOrderDetails", null);
      const response = await axios.post("/patient-user-test-info", params);
      commit("setOrderDetails", response.data);
    } catch (err) {
      console.log(err);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  createNewOrder: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setPatientOrderDetails", null);
      let response = await axios.post("/patient-user-info", params);
      commit("setPatientOrderDetails", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
    }
  },
  fetchOrderById: async ({ commit }, id) => {
    try {
      commit("setOrderByID", null);
      const response = await axios.get(`patient-orders/${id}`);
      commit("setOrderByID", response.data.data);
    } catch (err) {
      commit("setOrderByID", null);
      console.log(err);
    }
  },
  fetchOrdersByMobile: async ({ commit }, params) => {
    try {
      commit("setOrdersByMobile", null);
      const response = await axios.get(`patients-orders`, { params });
      commit("setOrdersByMobile", response.data);
    } catch (err) {
      commit("setOrdersByMobile", null);
      console.log(err);
    }
  },
  generateReports: async ({ commit }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setReportGenerateStatus", null);
      const response = await axios.post(
        "patient-order/generate-test-report",
        params
      );
      commit("setTestReportURL", response.data.data.path, {
        root: true,
      });
      commit("setReportGenerateStatus", true);

      let url = response.data.data.location;

      if (!params.skip_download) {
        window.open(url, "_blank");
      }
    } catch (error) {
      commit("setReportGenerateStatus", null);
    }
  },
  fetchAllCity: async ({ commit }, params) => {
    try {
      const response = await axios.get("/states/cities", { params });
      const cities =
        response.data && response.data.data && response.data.data[0]
          ? response.data.data[0].cities
          : [];
      commit("setAllCity", cities);
    } catch (error) {
      commit("setAllCity", []);
    }
  },
  clear: async ({ commit }) => {
    await commit("clear", null);
  },
  setPatientInfo({ commit }, params) {
    commit("setPatientInfo", params);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
