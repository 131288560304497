import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    profileData: null,
    profileUpdateStatus: null,
    profilePicUrlForHeader: null,
    profileUserNameForHeader: "",
    profileDesignationForHeader: "",
    statesData: null,
    testPanelsData: null,
  };
};

const state = initialState();
const getters = {
  getProfileData: (state) => state.profileData,
  getProfileUpdateStatus: (state) => state.profileUpdateStatus,
  getProfilePicUrlForHeader: (state) => state.profilePicUrlForHeader,
  getProfileUserNameForHeader: (state) => state.profileUserNameForHeader,
  getProfileDesignationForHeader: (state) => state.profileDesignationForHeader,
  getStatesData: (state) => state.statesData,
  getTestPanelsData: (state) => state.testPanelsData,
  getUserLabSettings: (state) => {
    return state.profileData &&
      state.profileData.lab &&
      state.profileData.lab.settings
      ? state.profileData.lab.settings
      : {};
  },
};

const mutations = {
  setProfileData(state, profile) {
    state.profileData = profile;
  },

  setProfileUpdateStatus(state, data) {
    state.profileUpdateStatus = data;
  },

  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },

  setProfilePicUrlForHeader(state, data) {
    state.profilePicUrlForHeader = data;
  },
  setProfileUserNameForHeader(state, data) {
    state.profileUserNameForHeader = data;
  },
  setProfileDesignationForHeader(state, data) {
    state.profileDesignationForHeader = data;
  },

  setStatesData(state, data) {
    state.statesData = data;
  },

  setTestPanelsData(state, data) {
    state.testPanelsData = data;
  },
};

const actions = {
  getProfile: async ({ commit, dispatch }) => {
    try {
      commit("setProfilePicUrlForHeader", null);
      let response = await axios.get("/profile");
      commit("setProfileData", response.data.data);
      commit("setProfilePicUrlForHeader", response.data.data.avatar);
      commit("setProfileUserNameForHeader", response.data.data.first_name);
      commit("setProfileDesignationForHeader", response.data.data.user_type);
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  updateProfile: async ({ commit, dispatch }, params) => {
    try {
      commit("setProfileUpdateStatus", null);
      let response = await axios.patch("/profile", params);
      commit("setProfileUpdateStatus", response.data);
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  updateProfilePicInHeader: async ({ commit }, updatedProfilePic) => {
    try {
      commit(
        "setProfilePicUrlForHeader",
        updatedProfilePic + "?q=" + Math.random()
      );
    } catch (error) {
      // console.log(error)
    }
  },

  getStates: async ({ commit }) => {
    try {
      let response = await axios.get("/states");
      commit("setStatesData", response.data.data);
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response);
      }
    }
  },
  fetchMyTestPanels: async ({ commit, dispatch }, params) => {
    try {
      let response = await axios.get("/users/me/test-panels", { params });
      commit("setTestPanelsData", response.data.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
