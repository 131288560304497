import AuthMiddleware from "@/middleware/auth";

const allSendOuts = () =>
  import(/* webpackChunkName: "sendouts" */ "@/views/sendOuts/AllSendOuts.vue");
const ActiveSendOuts = () =>
  import(
    /* webpackChunkName: "sendouts" */ "@/views/sendOuts/ActiveSendOuts.vue"
  );
const PendingSendOuts = () =>
  import(
    /* webpackChunkName: "sendouts" */ "@/views/sendOuts/PendingSendOuts.vue"
  );
const CompleteSendOuts = () =>
  import(
    /* webpackChunkName: "sendouts" */ "@/views/sendOuts/CompleteSendOuts.vue"
  );
const NewSendOut = () =>
  import(/* webpackChunkName: "sendouts" */ "@/views/sendOuts/NewSendOut");
const SendOutsInformation = () =>
  import(
    /* webpackChunkName: "sendouts" */ "@/components/sendOuts/SendOutsInformation"
  );

const SendOutPrint = () =>
  import(
    /* webpackChunkName: "sendouts" */ "@/views/sendOuts/SendOutPrint.vue"
  );

const ArchiveSendouts = () =>
  import(
    /* webpackChunkName: "sendouts" */ "@/views/sendOuts/ArchiveSendouts.vue"
  );

const SendOutsRouter = [
  {
    path: "/all-send-outs",
    name: "allSendOuts",
    component: allSendOuts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/all-send-outs/active",
    name: "ActiveSendOuts",
    component: ActiveSendOuts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/all-send-outs/pending",
    name: "PendingSendOuts",
    component: PendingSendOuts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/all-send-outs/finalized",
    name: "CompleteSendOuts",
    component: CompleteSendOuts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/send-outs",
    name: "SendOutsView",
    component: NewSendOut,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/send-outs/:sendout_id/edit",
    name: "SendOutsEdit",
    component: NewSendOut,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/send-outs/:sendout_id",
    name: "SendoutsInformation",
    component: SendOutsInformation,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/send-outs-print/:sendout_id",
    name: "SendOutPrint",
    component: SendOutPrint,
    beforeEnter: AuthMiddleware.userOrServer,
    meta: {
      title: "SEND OUTS",
    },
  },
  {
    path: "/all-send-outs/archive",
    name: "ArchiveSendouts",
    component: ArchiveSendouts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SEND OUTS",
    },
  },
];

export { SendOutsRouter };
