import axios from "@/config/axios";

const initialState = () => {
  return {
    allPatients: [],
    singlePatient: null,
  };
};

const state = initialState();

const getters = {
  getAllPatients: (state) => state.allPatients,
  getSinglePatient: (state) => state.singlePatient,
};

const mutations = {
  setAllPatients(state, data) {
    state.allPatients = data;
  },
  setSinglePatient(state, data) {
    state.singlePatient = data;
  },

  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllPatients: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", [], { root: true });

      let response = await axios.get("/lookup/cases?", { params: params });

      commit("setAllPatients", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },

  fetchSinglePatient: async ({ commit, dispatch }, id) => {
    try {
      dispatch("errors/clear", [], { root: true });

      let response = await axios.get(`/patients/${id}`);

      commit("setSinglePatient", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
