import AuthMiddleware from "@/middleware/auth"

const AllEMROrders = () =>
  import(/* webpackChunkName: "emrorders" */ "@/views/emrOrders/AllEMROrders")
const ActiveEMROrders = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/ActiveEMROrders"
  )
const PendingEMROrders = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/PendingEMROrders"
  )
const CompleteEMROrders = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/CompleteEMROrders"
  )
const ArchiveEMROrders = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/ArchiveEMROrders"
  )
const ViewEmrOrders = () =>
  import(/* webpackChunkName: "emrorders" */ "@/views/emrOrders/ViewEMROrders")

//QC Charts
const EmrCaseInformation = () =>
  import(/* webpackChunkName: "emrorders" */ "@/components/EMROrders/ViewIndex")
const ViewConfiguration = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/components/EMROrders/Configuration"
  )
const FinalizedReports = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/components/EMROrders/FinalizedReports"
  )
const ValidationEMROrders = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/IsValidationEMROrders"
  )
const ActiveValidations = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/ActiveValidations"
  )
const ArchiveValidations = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/ArchiveValidations"
  )
const CompleteValidations = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/CompleteValidations"
  )
const PartialEMROrders = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/PartialEMROrders"
  )
const NotSentEMROrders = () =>
  import(
    /* webpackChunkName: "emrorders" */ "@/views/emrOrders/NotSentEMROrders"
  )
// //QC Charts

const EmrRouter = [
  {
    path: "/all-emr-orders",
    name: "AllEMROrders",
    component: AllEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EMR ORDERS",
    },
  },

  {
    path: "/all-emr-orders/active",
    name: "ActiveEMROrders",
    component: ActiveEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EMR ORDERS",
    },
  },
  {
    path: "/all-emr-orders/pending",
    name: "PendingEMROrders",
    component: PendingEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EMR ORDERS",
    },
  },
  {
    path: "/all-emr-orders/finalized",
    name: "CompleteEMROrders",
    component: CompleteEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EMR ORDERS",
    },
  },
  {
    path: "/all-emr-orders/archive",
    name: "ArchiveEMROrders",
    component: ArchiveEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EMR ORDERS",
    },
  },
  {
    path: "/all-emr-orders/partial",
    name: "PartialEMROrders",
    component: PartialEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EMR ORDERS",
    },
  },
  {
    path: "/all-emr-orders/not-sent",
    name: "NotSentEMROrders",
    component: NotSentEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EMR ORDERS",
    },
  },
  {
    path: "/emr-orders/view",
    name: "ViewEmrOrders",
    component: ViewEmrOrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "View EMR Orders",
    },
    children: [
      {
        path: "/emr-orders/:order_id",
        name: "View-Case-Information",
        component: EmrCaseInformation,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "Case Information",
        },
      },
      {
        path: "/emr-orders/:order_id/finalized-reports",
        name: "Finalized-Reports",
        component: FinalizedReports,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "Finalized Reports",
        },
      },
      {
        path: "/emr-orders/:order_id/configuration",
        name: "View-Configuration",
        component: ViewConfiguration,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "View Configuration",
        },
      },
    ],
  },
  {
    path: "/validation-emr-orders",
    name: "ValidationEMROrders",
    component: ValidationEMROrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION EMR ORDERS",
    },
  },
  {
    path: "/validation-emr-orders/active",
    name: "ActiveValidationEMROrders",
    component: ActiveValidations,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION EMR ORDERS",
    },
  },
  {
    path: "/validation-emr-orders/rejected",
    name: "ArchiveValidationEMROrders",
    component: ArchiveValidations,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION EMR ORDERS",
    },
  },
  {
    path: "/validation-emr-orders/complete",
    name: "CompleteValidationEMROrders",
    component: CompleteValidations,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION EMR ORDERS",
    },
  },
]

export { EmrRouter }
