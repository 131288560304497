import AuthMiddleware from "@/middleware/auth"

const NewOrder = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/NewPatientOrder")
const AllOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/AllOrders")
const ActiveOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/ActiveOrders")
const CompleteOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/CompleteOrders")
const ArchiveOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/ArchiveOrders")
const RejectionOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/RejectionOrders");
const PendingOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/PendingOrders");
const ViewOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/ViewOrder")

const ValidationOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/IsValidationOrders")
const ActiveValidations = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/ActiveValidations")
const CompleteValidations = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/CompleteValidations")
const ArchiveValidations = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/ArchiveValidations")

const Reorder = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/Reorder")
const PartialOrders = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/PartialOrders")
const ScanQrCode = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/ScanQrCode")
// const FeatureOrders = () =>
//   import(/* webpackChunkName: "orders" */ "@/views/orders/FeatureOrders")

const OrdersRouter = [
  {
    path: "/new-order",
    name: "NewOrder",
    component: NewOrder,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "NEW ORDER",
    },
  },

  {
    path: "/all-orders",
    name: "AllOrders",
    component: AllOrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "LABSQUIRE ORDERS",
    },
  },

  {
    path: "/all-orders/active",
    name: "ActiveOrders",
    beforeEnter: AuthMiddleware.user,
    component: ActiveOrders,
    meta: {
      title: "LABSQUIRE ORDERS",
    },
  },
  {
    path: "/all-orders/finalized",
    name: "CompleteOrders",
    beforeEnter: AuthMiddleware.user,
    component: CompleteOrders,
    meta: {
      title: "LABSQUIRE ORDERS",
    },
  },
  {
    path: "/all-orders/archive",
    name: "ArchiveOrders",
    beforeEnter: AuthMiddleware.user,
    component: ArchiveOrders,
    meta: {
      title: "LABSQUIRE ORDERS",
    },
  },
  {
    path: "/all-orders/partial",
    name: "PartialOrders",
    beforeEnter: AuthMiddleware.user,
    component: PartialOrders,
    meta: {
      title: "LABSQUIRE ORDERS",
    },
  },
  {
    path: "/all-orders/rejection",
    name: "RejectionOrders",
    beforeEnter: AuthMiddleware.user,
    component: RejectionOrders,
    meta: {
      title: "LABSQUIRE ORDERS",
    },
  },
  {
    path: "/all-orders/pending",
    name: "PendingOrders",
    beforeEnter: AuthMiddleware.user,
    component: PendingOrders,
    meta: {
      title: "LABSQUIRE ORDERS",
    },
  },
  // {
  //   path: "/all-orders/future",
  //   name: "FeatureOrders",
  //   beforeEnter: AuthMiddleware.user,
  //   component: FeatureOrders,
  //   meta: {
  //     title: "LABSQUIRE ORDERS",
  //   },
  // },
  {
    path: "/orders/:order_id/edit",
    name: "Edit-Order-Info",
    component: NewOrder,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EDIT LABSQUIRE ORDER",
    },
  },

  {
    path: "/orders/:order_id",
    name: "Order-Information",
    component: ViewOrders,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/validation-orders",
    name: "ValidationOrders",
    component: ValidationOrders,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION LABSQUIRE ORDERS",
    },
  },
  {
    path: "/validation-orders/active",
    name: "ActiveValidations",
    component: ActiveValidations,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION LABSQUIRE ORDERS",
    },
  },
  {
    path: "/validation-orders/complete",
    name: "CompleteValidations",
    component: CompleteValidations,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION LABSQUIRE ORDERS",
    },
  },
  {
    path: "/validation-orders-archi",
    name: "ArchiveValidations",
    component: ArchiveValidations,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION LABSQUIRE ORDERS",
    },
  },
  {
    path: "/re-order/:order_id",
    name: "Re-Order",
    component: Reorder,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Re Order",
    },
  },
  {
    path: "/order-to-case",
    name: "ScanQrCode",
    component: ScanQrCode,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "SCAN QR CODE",
    },
  },
]

export { OrdersRouter }
