import axios from "@/config/axios";

const initialState = () => {
  return {
    allBloodResultConfig: {},
    singleBloodConfig: {},
    resultsUpdateStatus: false,
  };
};

const state = initialState();

const getters = {
  getAllBloodResultConfig: (state) => state.allBloodResultConfig,
  getSingleResultConfig: (state) => state.singleBloodConfig,
  getBloodResultUpdateStatus: (state) => state.resultsUpdateStatus,
};

const mutations = {
  setAllBloodResultConfig(state, data) {
    state.allBloodResultConfig = data;
  },
  setSingleTestConfig(state, data) {
    state.singleBloodConfig = data;
  },
  setTestResultUpdateStatus(state, status) {
    state.resultsUpdateStatus = status;
  },
  reset(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllBloodResultConfig: async ({ commit }, params) => {
    try {
      commit("setAllBloodResultConfig", null);
      const response = await axios.get("/blood-test-config", { params });
      commit("setAllBloodResultConfig", response.data.response);
    } catch (err) {
      commit("setAllBloodResultConfig", null, { root: true });
    }
  },
  fetchSingleBloodConfig: async ({ commit }, id) => {
    try {
      commit("setSingleTestConfig", null);
      let response = await axios.get(`/blood-test-config/${id}`);
      commit("setSingleTestConfig", response.data.data);
    } catch (err) {
      commit("setSingleTestConfig", null);
    }
  },
  updateResults: async ({ commit, dispatch }, params) => {
    let id = params.configId;
    delete params.configId;
    try {
      commit("setTestResultUpdateStatus", false);
      await axios.put("/blood-test-config/" + id, params);
      commit("setTestResultUpdateStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
