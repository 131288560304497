import { createRouter, createWebHistory } from "vue-router"
import AuthMiddleware from "@/middleware/auth"
const Home = () => import(/* webpackChunkName: "routes" */ "@/views/Home")
const SignIn = () =>
  import(/* webpackChunkName: "routes" */ "@/views/auth/SignIn.vue")
const Profile = () =>
  import(/* webpackChunkName: "routes" */ "@/views/auth/Profile.vue")
const GlobalSearch = () =>
  import(/* webpackChunkName: "routes" */ "@/views/GlobalSearch")
const Exports = () =>
  import(/* webpackChunkName: "routes" */ "@/components/Exports")

const PharmacyExports = () =>
  import(/* webpackChunkName: "routes" */ "@/components/PharmacyExports")

const MultipleBarcodes = () =>
  import(/* webpackChunkName: "routes" */ "@/views/cases/MultipleBarcodes")
const MultipleQrcodes = () =>
  import(/* webpackChunkName: "routes" */ "@/views/cases/MultipleQrcodes")
const OrderMultipleBarcodes = () =>
  import(/* webpackChunkName: "routes" */ "@/views/orders/MultipleBarcodes")
const MultipleTestBarcodes = () =>
  import(/* webpackChunkName: "routes" */ "@/views/cases/MultipleTestBarcodes")
const EmailLogs = () =>
  import(/* webpackChunkName: "routes" */ "@/components/emailLogs/AllEmailLogs")

//logs

const CasesLogs = () => import("@/views/logs/CasesLogs")
const OrdersLogs = () => import("@/views/logs/OrdersLogs")
const TsiReportsList = () => import("@/components/tsi/AllReports")
const TsiSingleReportView = () => import("@/components/tsi/ViewReport")
const PgxUploadReport = () => import("@/components/tsi/UploadReports")
const ReportPreview = () => import("@/components/tsi/ReportPreview")
const PrintRequisition = () => import("@/components/orders/PrintRequisition")
const PrintCaseRequisition = () => import("@/components/cases/PrintRequisition")
const EMROrdersLogs = () => import("@/views/logs/EMROrdersLogs")
const ResultFileLogs = () => import("@/views/logs/ResultFileLogs")
const UserAccountLogs = () => import("@/views/logs/UserAccountLogs")

import { CaseRouter } from "@/router/casesRouter"

import { billingCasesRouter } from "@/router/billingCasesRouter"

import { ResultFilesRouter } from "@/router/resultFilesRouter"

import { QCChartsRouter, QCChartNonChildRoutes } from "@/router/qcCharts"

import { SendOutsRouter } from "@/router/sendOutsRouter"

import { ReceivedSendOutsRouter } from "@/router/receivedSendOutsRouter"

import { HospitalRouter } from "@/router/hospitalRouter"

import { PhysicianRouter } from "@/router/physicianRouter"

import { OrdersRouter } from "@/router/ordersRouter"

import { EmrRouter } from "@/router/emrRouter"

import { UserAccountsRouter } from "@/router/userAccountsRouter"

import { PendingCasesRouter } from "@/router/pendingCasesRouter"

import { topdogRouters } from "@/router/topDog"

import { BloodRouter } from "@/router/bloodRouter"

import { BulkAccessionsRouter } from "@/router/bulkAccessionsRouter"

import { MarketerRouter } from "@/router/marketerRouter"

import { testProfileRouter } from "@/router/testProfileRouter"

import { RevenueRouter } from "@/router/revenueRouter"

import { PhlebioRouter } from "@/router/phlebioRouter"

// import { pendingClientRoutes } from "@/router/clientPendingRouter"
//Lab documents start here

const LabDocumentFolders = () =>
  import(
    /* webpackChunkName: "routes" */ "@/components/labDocuments/LabDocumentFolders"
  )

// Lab documents end here

//Supply-request

const SupplyRequest = () =>
  import(/* webpackChunkName: "routes" */ "@/views/SupplyRequest")
const CovidDrive = () =>
  import(/* webpackChunkName: "routes" */ "@/views/CovidDrive")

// Logistics

const LabInformation = () =>
  import(/* webpackChunkName: "routes" */ "@/views/labs/LabInformation.vue")

const batchList = () =>
  import(/* webpackChunkName: "routes" */ "@/views/batching/BatchList.vue")

const icd10Codes = () =>
  import(
    /* webpackChunkName: "routes" */ "@/components/allIcd10Codes/Icd10Codes.vue"
  )

const updatePassword = () =>
  import(/* webpackChunkName: "routes" */ "@/components/UpdatePassword.vue")
const ProfileEdit = () =>
  import(/* webpackChunkName: "routes" */ "@/components/auth/ProfileEdit.vue")
const SupplementaryReportView = () =>
  import(
    /* webpackChunkName: "routes" */ "@/views/cases/SupplementaryReportView"
  )
const TestReports = () =>
  import(/* webpackChunkName: "routes" */ "@/views/cases/TestReports")
const ResultFileStatusDetailsReport = () =>
  import(
    /* webpackChunkName: "routes" */ "@/views/resultFiles/ResultFileStatusDetailsReport"
  )
const ResultFileStatusDetailsTestReports = () =>
  import(
    /* webpackChunkName: "routes" */ "@/views/resultFiles/ResultFileStatusDetailsTestReports"
  )
const ResultFileStatusDetailsBloodHTMLDATA = () =>
  import(/* webpackChunkName: "routes" */ "@/components/cases/HtmlReportView")
const ResultFileStatusDetailsUrineHTMLDATA = () =>
  import("@/components/cases/HtmlUrineReportPreview")
const ToxicologyTestResultsPreview = () =>
  import("@/components/cases/ToxicologyResultsPreview")

// const EditUserAccount = () => import("@/components/addUser/editUserAccount");
import(
  /* webpackChunkName: "routes" */ "@/components/cases/HtmlUrineReportPreview"
)

const ArchiveCases = () =>
  import(/* webpackChunkName: "routes" */ "@/components/cases/ArchiveCases")
const ArchiveOrders = () =>
  import(/* webpackChunkName: "routes" */ "@/components/orders/ArchiveOrders")

const LabCategoryFiles = () =>
  import(
    /* webpackChunkName: "routes" */ "@/components/labDocuments/LabDocumentsFiles"
  )

// cardaic reports

const EditHospitalPhysician = () =>
  import(
    /* webpackChunkName: "routes" */ "@/components/hospitals/physicians/HospitalPhysicianEdit.vue"
  )
const ReportsList = () =>
  import(/* webpackChunkName: "routes" */ "@/views/cardiac/ReportsList.vue")
const PulmonaryReportsList = () =>
  import(
    /* webpackChunkName: "routes" */ "@/views/cardiac/PulmonaryReportsList.vue"
  )
const CaseReportStatus = () =>
  import(
    /* webpackChunkName: "routes" */ "@/views/cardiac/CaseReportStatus.vue"
  )
const AddReports = () =>
  import(/* webpackChunkName: "routes" */ "@/views/cardiac/AddCaseReports.vue")
const ToxicologyTestResults = () =>
  import(
    /* webpackChunkName: "routes" */ "@/components/cases/ToxicologyTestResults"
  )
const Toxicology = () =>
  import(
    /* webpackChunkName: "routes" */ "@/components/reports/toxicology/ReportPage.vue"
  )

const PatientReportPreview = () => import("@/views/PatientReportPreview")
const PatientEmailReportPreview = () => import("@/views/PatientReportEmailView")
const ErequisitionView = () => import("@/views/cases/ErequisitionView")
const ErequisitionViewOrders = () =>
  import("@/components/orders/ErequisitionViewOrders")
const Attachments = () => import("@/views/cases/Attachments")
const ClientPendingOrder = () =>
  import(
    /* webpackChunkName: "clientPenidngOrders" */ "@/views/pendingClientOrders/ClientPendingOrder"
  )
const UaQcResults = () => import("@/components/cases/UaQcResults")
const UaBarcodes = () =>
  import(/* webpackChunkName: "routes" */ "@/views/cases/UaQcBarcodes")
const ErequisitionEmrView = () =>
  import("@/components/EMROrders/ErequisitionView")
const PrintEMRRequisition = () => import("@/components/EMROrders/PrintRequisition")
const PrintUrinalysisResults = () =>
  import(/* webpackChunkName: "routes" */ "@/components/cases/PrintUaResults")

const routes = [
  {
    path: "/",
    component: Home,

    children: [
      ...topdogRouters,
      ...CaseRouter,
      ...OrdersRouter,
      ...EmrRouter,
      ...billingCasesRouter,
      ...UserAccountsRouter,
      ...PendingCasesRouter,
      ...ResultFilesRouter,
      ...QCChartsRouter,
      ...SendOutsRouter,
      ...ReceivedSendOutsRouter,
      ...HospitalRouter,
      ...PhysicianRouter,
      ...BloodRouter,
      ...BulkAccessionsRouter,
      ...MarketerRouter,
      ...testProfileRouter,
      ...RevenueRouter,
      ...PhlebioRouter,
      {
        path: "/archive/cases",
        name: "ArchiveDataCases",
        component: ArchiveCases,
        beforeEnter: AuthMiddleware.user,
      },

      {
        path: "/archive/orders",
        name: "ArchiveDataOrders",
        component: ArchiveOrders,
        beforeEnter: AuthMiddleware.user,
      },

      {
        path: "/DLW/supply-request",
        name: "DLW-Supply-Request",
        component: SupplyRequest,
      },
      {
        path: "/MGH/supply-request",
        name: "MGH-Supply-Request",
        component: SupplyRequest,
      },
      {
        path: "/supply-request/success",
        name: "Supply-Request-Success",
        component: CovidDrive,
      },

      {
        path: "/gened-test-results",
        name: "ReportsList",
        component: ReportsList,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "GENED TEST RESULTS",
        },
      },
      {
        path: "/gened-test-results/pulmonary",
        name: "PulmonaryReportsList",
        component: PulmonaryReportsList,
        meta: {
          title: "GENED TEST RESULTS",
        },
      },

      //labs

      {
        path: "/labs-documents",
        name: "LabDocumentFolders",
        component: LabDocumentFolders,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "LAB DOCUMENTS",
        },
      },

      {
        path: "/labs-documents/:folder_id/:folder_name",
        name: "LabCategoryFiles",
        component: LabCategoryFiles,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "Lab Documents",
        },
      },

      //labs

      {
        path: "/gened-test-results/add",
        name: "addGenedTestResults",
        component: AddReports,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "Gened Test Results",
        },
      },
      {
        path: "/gened-test-results/edit/:id",
        name: "editGenedTestResults",
        component: AddReports,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "Gened Test Results",
        },
      },
      {
        path: "/gened-test-results/view/:id",
        name: "viewGenedTestResults",
        component: CaseReportStatus,
        meta: {
          title: "GENED TEST RESULTS",
        },
      },

      {
        path: "/lab-information",
        name: "LabInformation",
        component: LabInformation,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "LABORATORY",
        },
      },

      {
        path: "/physician/:physician_id/edit",
        name: "EditHospitalPhysician",
        component: EditHospitalPhysician,
        meta: {
          title: "PHYSICIAN",
        },
      },

      {
        path: "/update-password",
        name: "updatePassword",
        component: updatePassword,
        meta: {
          title: "UPDATE PASSWORD",
        },
      },
      {
        path: "/user-profile",
        name: "Profile",
        beforeEnter: AuthMiddleware.user,
        component: Profile,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "USER PROFILE",
        },
      },
      {
        path: "/edit-profile",
        name: "ProfileEdit",
        component: ProfileEdit,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "PROFILE EDIT",
        },
      },

      {
        path: "/global-search",
        name: "GlobalSearch",
        component: GlobalSearch,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "GLOBAL SEARCH",
        },
      },

      {
        path: "/all-report-download",
        name: "Exports",
        component: Exports,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "REPORTS",
        },
      },
      {
        path: "/pharmacy-report-download",
        name: "PharmacyExports",
        component: PharmacyExports,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "PHARMACY REPORTS",
        },
      },

      {
        path: "/logs/cases",
        name: "casesLogs",
        component: CasesLogs,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "LOGS",
        },
      },
      {
        path: "/logs/orders",
        name: "ordersLogs",
        component: OrdersLogs,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "LOGS",
        },
      },
      {
        path: "/logs/emr-orders",
        name: "emrOrdersLogs",
        component: EMROrdersLogs,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "LOGS",
        },
      },
      {
        path: "/logs/result-files",
        name: "resultFileLogs",
        component: ResultFileLogs,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "LOGS",
        },
      },
      {
        path: "/logs/general-logs",
        name: "generalLogs",
        component: UserAccountLogs,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "LOGS",
        },
      },
      {
        path: "/email-logs",
        name: "EmailLogs",
        component: EmailLogs,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "EMAIL LOGS",
        },
      },
      {
        path: "/pgx-reports",
        name: "TsiReports",
        component: TsiReportsList,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "PGX REPORTS",
        },
      },
      {
        path: "/view-tsi-report/:id",
        name: "ViewTsiReoport",
        component: TsiSingleReportView,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "PGX REPORTS",
        },
      },
      {
        path: "/pgx-reports/upload-pgx-report",
        name: "UploadPgxReport",
        component: PgxUploadReport,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "UPLOAD PGX REPORTS",
        },
      },

      {
        path: "/icd-10-codes",
        name: "icd10codes",
        component: icd10Codes,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "ICD 10 CODES",
        },
      },
      {
        path: "/batching",
        name: "batchList",
        component: batchList,
        meta: {
          title: "BATCHING",
        },
      },

      {
        path: "/toxicology-test-results/:result_file_id/report/:report_id/:test_results_id",
        name: "toxicology-test-results",
        component: ToxicologyTestResults,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/client-pending-orders/:order_id/details",
        name: "ClientPendingOrder",
        component: ClientPendingOrder,
        beforeEnter: AuthMiddleware.userOrServer,
        meta: {
          title: "PENDING ORDER",
        },
      }, {
        path: '/ua-qc-results',
        name: 'UaQcResults',
        component: UaQcResults,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: 'UA QC RESULTS'
        }
      },
    ],
  },
  // ...pendingClientRoutes,

  {
    path: "/cases/test-reports/:case_id",
    name: "ViewTestReports",
    component: TestReports,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
    beforeEnter: AuthMiddleware.guest,
  },

  {
    path: "/result-files/supplementary-report/:case_id/report/:report_id",
    name: "supplementaryReportView",
    component: SupplementaryReportView,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/result-files/:result_file_id/:report_id/:test_results_id",
    name: "ResultFileReport",
    component: ResultFileStatusDetailsReport,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/e-requisition-view-case/:case_id",
    name: "ErequisitionView",
    component: ErequisitionView,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/e-requisition-view-order/:order_id",
    name: "ErequisitionViewOrders",
    component: ErequisitionViewOrders,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/e-requisition-view-emr-order/:order_id",
    name: "ErequisitionEmrView",
    component: ErequisitionEmrView,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/cases/attachments-view/:path",
    name: "ViewAttachments",
    component: Attachments,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/result-files/regular-report/:case_id/report/:report_id",
    name: "ResultFileTestReports",
    component: ResultFileStatusDetailsTestReports,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/tsi-report/:result_file_id/:report_id",
    name: "ReportPreview",
    component: ReportPreview,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  // html view
  {
    path: "/result-files/:case_id/report/:accession_id/blood-results",
    name: "ResultFileBloodHTMLData",
    component: ResultFileStatusDetailsBloodHTMLDATA,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/result-files/:case_id/report/:accession_id/urine-results",
    name: "ResultFileUrineHTMLData",
    component: ResultFileStatusDetailsUrineHTMLDATA,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/result-files/report/:case_id/toxicology-results",
    name: "ToxicologyTestResultsPreview",
    component: ToxicologyTestResultsPreview,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/result-files/:result_file_id/report/:report_id/:test_results_id/toxicology",
    name: "ResultFileToxicologyReport",
    component: Toxicology,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/order-e-requisition/:order_id/print",
    name: "ErequisitionPrintData",
    component: PrintRequisition,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/case-e-requisition/:case_id/print",
    name: "ErequisitionCasePrintData",
    component: PrintCaseRequisition,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  {
    path: "/emr-order-e-requisition/:order_id/print",
    name: "ErequisitionEMRPrintData",
    component: PrintEMRRequisition,
    beforeEnter: AuthMiddleware.userOrServer,
  },
  // we wont include multiple barcodes in case children to avoid side navbar in case details
  {
    path: "/multiple-barcodes",
    name: "MultipleBarcodes",
    component: MultipleBarcodes,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/multiple-qrcodes",
    name: "MultipleQrcodes",
    component: MultipleQrcodes,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/orders/multiple-barcodes",
    name: "OrderMultipleBarcodes",
    component: OrderMultipleBarcodes,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/multiple-test-barcodes",
    name: "MultipleTestBarcodes",
    component: MultipleTestBarcodes,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/patient-case-report/:report_id",
    name: "patient-report-preview",
    component: PatientReportPreview,
  },
  {
    path: "/patient-case-reports/:report_id",
    name: "patient-report-email-preview",
    component: PatientEmailReportPreview,
  },
  {
    path: "/ua-qc-barcodes",
    name: "QcBrcode",
    component: UaBarcodes,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: '/print-urinalysis-results',
    name: 'PrintUrinalysisResults',
    component: PrintUrinalysisResults,
    beforeEnter: AuthMiddleware.user,

  },
  ...QCChartNonChildRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
