import axios from "@/config/axios"

const initiateState = () => {
  return {
    allIcd10Codes: {},
    allCptCodes: {},
  }
}
const state = initiateState()
const getters = {
  getAllIcd10Codes: (state) => state.allIcd10Codes,
  getAllCptCodes: (state) => state.allCptCodes,
}
const mutations = {
  setAllIcd10Codes(state, data) {
    state.allIcd10Codes = data
  },
  setAllCptCodes(state, data) {
    state.allCptCodes = data
  },
  reset: (state) => {
    const initial = initiateState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  fetAllIcd10Codes: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("icd-10-codes", { params })

      commit("setAllIcd10Codes", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  fetAllCptCodes: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("cpt-codes", { params })

      commit("setAllCptCodes", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
}
export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
}
