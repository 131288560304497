import AuthMiddleware from "@/middleware/auth";

const BloodQCChartReport = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodQCCharts/QcReportView"
  );
const BloodQCLevel = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodQCLevel/BloodQCLevel"
  );
const AllConfigTestDetails = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodInstrumentTestConfig/AllBloodInstrumentTests"
  );
const BloodInstrumentTestConfig = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodInstrumentTestConfig/AddBloodInstrumentTestConfig"
  );
const AllBloodInstrumentTestLevels = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodInstrumentTestLevels/AllBloodInstrumentTestLevels"
  );
const AddBloodInstrumentTestLevel = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodInstrumentTestLevels/AddBloodInstrumentTestLevel"
  );
const AllBloodResultsConfig = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodResultConfig/AllResultConfig"
  );
const BloodResultEditConfig = () =>
  import(
    /* webpackChunkName: "blood" */ "@/components/bloodResultConfig/ResultConfigEdit"
  );

const BloodRouter = [
  {
    path: "/blood-qc-chart",
    name: "BloodQCchart",
    component: BloodQCChartReport,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD QC CHART",
    },
  },
  {
    path: "/blood-qc-level",
    name: "BloodQCLevel",
    component: BloodQCLevel,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD QC LEVEL",
    },
  },
  {
    path: "/all-blood-instrument-test-config",
    name: "AllInstrumentTestConfig",
    component: AllConfigTestDetails,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD INSTRUMENT TESTS",
    },
  },
  {
    path: "/blood-instrument-test-config/add",
    name: "Blood-Test-Config-Creation",
    component: BloodInstrumentTestConfig,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD INSTRUMENT TEST CONFIG",
    },
  },
  {
    path: "/blood-instrument-test-config/:test_id/edit",
    name: "Blood-Test-Config-Information-Update",
    component: BloodInstrumentTestConfig,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD INSTRUMENT TEST CONFIG ",
    },
  },
  {
    path: "/all-blood-instrument-test-levels/:test_id",
    name: "All-Blood-Instrument-Test-Level",
    component: AllBloodInstrumentTestLevels,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD INSTRUMENT TEST LEVELS ",
    },
  },
  {
    path: "/blood-instrument-test-levels/:test_id/edit/:level_id",
    name: "Update-Blood-Instrument-Test-Level",
    component: AddBloodInstrumentTestLevel,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD INSTRUMENT TEST LEVEL ",
    },
  },
  {
    path: "/blood-instrument-test-levels/:test_id/add",
    name: "Add-Blood-Instrument-Test-Level",
    component: AddBloodInstrumentTestLevel,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ADD BLOOD INSTRUMENT TEST LEVEL ",
    },
  },
  {
    path: "/all-blood-result-config",
    name: "bloodResult",
    component: AllBloodResultsConfig,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BLOOD RESULT CONFIG",
    },
  },
  {
    path: "/blood-result-config/:config_id",
    name: "BloodResultsEdit",
    component: BloodResultEditConfig,
    beforeEnter: AuthMiddleware.user,

    meta: {
      title: "BLOOD RESULT CONFIG",
    },
  },
];

export { BloodRouter };
