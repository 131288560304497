import axios from "@/config/axios";

const initialState = () => {
  return {
    allBloodQcLevels: {},
    createBloodQcLevels: false,
    updateLevelStatus: false,
    setArchiveStatus: false,
  };
};

const state = initialState();

const getters = {
  getAllBloodQcLevels: (state) => state.allBloodQcLevels,
  getCreateBloodQcLevels: (state) => state.createBloodQcLevels,
  getUpdateQcLevelStatus: (state) => state.updateLevelStatus,
  getArchiveStatus: (state) => state.setArchiveStatus,
};

const mutations = {
  setAllBloodQcLevels(state, data) {
    state.allBloodQcLevels = data;
  },
  setCreateBloodQcLevels(state, status) {
    state.createBloodQcLevels = status;
  },
  setUpdateQcLevelStatus(state, status) {
    state.updateLevelStatus = status;
  },
  setArchiveStatus(state, status) {
    state.setArchiveStatus = status;
  },
  reset(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllBloodQcLevelsData: async ({ commit }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setAllBloodQcLevels", null);
      const response = await axios.get("/blood-qc-levels", { params });
      commit("setAllBloodQcLevels", response.data.response);
    } catch (err) {
      commit("setAllBloodQcLevels", null);
    }
  },
  createBloodQcLevelsData: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setCreateBloodQcLevels", false);
      await axios.post("/blood-qc-levels", params);
      commit("setCreateBloodQcLevels", true);
    } catch (err) {
      commit("setCreateBloodQcLevels", false);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  updateQcLevel: async ({ commit, dispatch }, params) => {
    let level_id = params.level_id;
    delete params.level_id;

    try {
      commit("errors/clear", null, { root: true });
      commit("setUpdateQcLevelStatus", false);
      await axios.put(`/blood-qc-levels/${level_id}`, params);
      commit("setUpdateQcLevelStatus", true);
    } catch (err) {
      commit("setUpdateQcLevelStatus", false);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  archiveLevel: async ({ commit }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setArchiveStatus", false);
      await axios.delete("/blood-qc-levels/" + params.id);
      commit("setArchiveStatus", true);
    } catch (err) {
      commit("setArchiveStatus", false);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
