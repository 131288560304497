import axios from "@/config/axios";

const initialState = () => {
  return {
    BloodInstrumentTestLevels: {},
    singleInstrumentTestLevel: null,
    createTestLevelStatus: false,
    updateTestLevelStatus: false,
  };
};

const state = initialState();

const getters = {
  getAllBloodInstrumentTestLevels: (state) => state.BloodInstrumentTestLevels,
  getSingleBloodInstrumentTestLevel: (state) => state.singleInstrumentTestLevel,
  getCreateTestLevelStatus: (state) => state.createTestLevelStatus,
  getUpdateTestLevelStatus: (state) => state.updateTestLevelStatus,
};

const mutations = {
  setBloodInstrumentTestLevels(state, data) {
    state.BloodInstrumentTestLevels = data;
  },
  setSingleInstrumentTestLevel(state, data) {
    state.singleInstrumentTestLevel = data;
  },
  setBloodInstrumentLevelStatus(state, status) {
    state.createTestLevelStatus = status;
  },
  setBloodInstrumentLevelUpdateStatus(state, status) {
    state.updateTestLevelStatus = status;
  },

  reset(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {
  fetchBloodInstrumentLevels: async ({ commit }, params) => {
    let test_id = params.id;
    delete params.id;
    try {
      commit("setBloodInstrumentTestLevels", null);
      const response = await axios.get(
        "/blood-instrument-test-levels/" + test_id,
        { params }
      );

      commit("setBloodInstrumentTestLevels", response.data.response);
    } catch (err) {
      commit("setBloodInstrumentTestLevels", null);
    }
  },
  fetchSingleInstrumentLevel: async ({ commit, dispatch }, params) => {
    try {
      commit("setSingleInstrumentTestLevel", null);
      const response = await axios.get(
        "/blood-instrument-test-levels/" +
          params.test_id +
          "/level/" +
          params.level_id
      );

      commit("setSingleInstrumentTestLevel", response.data);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  saveBloodInstrumentTestLevel: async ({ commit, dispatch }, params) => {
    let test = params.test_id;
    delete params.test_id;
    try {
      commit("setBloodInstrumentLevelStatus", false);
      await axios.post("/blood-instrument-test-levels/" + test, params);
      commit("setBloodInstrumentLevelStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  updateBloodInstrumentTestLevel: async ({ commit, dispatch }, params) => {
    let test = params.test_id;
    delete params.test_id;
    let level = params.level_id;
    delete params.level_id;
    try {
      commit("setBloodInstrumentLevelUpdateStatus", false);
      await axios.put(
        "/blood-instrument-test-levels/" + test + "/level/" + level,
        params
      );
      commit("setBloodInstrumentLevelUpdateStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
