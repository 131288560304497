import axios from "@/config/axios";

const initialState = () => {
  return {
    charts: []
  }
};

const state = initialState();


const getters = {
  getAllCharts: state => state.charts,
};

const mutations = {
  setAllCharts(state, charts) {
    state.charts = charts
  },
  reset: state => {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  fetchAllCharts: async ({commit,dispatch},params ) => {
    try {
     await dispatch("errors/clear", [], { root: true });

     const response = await axios.get("/result-files-covid", { params });
      
      commit("setAllCharts", response.data);
    }
    catch (error) {
      if (error.response) {
      await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
