import axios from "@/config/axios";

const initialState = () => {
  return {
    users: [],
    userAccountAddStatus: false,
    errorData: {},
    allUserAccounts: {},
    labAdmins: {},
    labManagers: {},
    userAccountUpdateStatus: false,
    userAccountDeleteStatus: false,
    userAccountStatusUpdated: false,
    userPasswordResetStatus: false,
    userAccountDataById: {},
    toggleEmailNotificationStatus: false,
    emailNotificationSettings: false,
  };
};

const state = initialState();

const getters = {
  getAllUsers: (state) => state.users,
  getAllUserAccounts: (state) => state.allUserAccounts,
  getUserAccountAddStatus: (state) => state.userAccountAddStatus,
  getUserAccountErrors: (state) => state.errorData,
  getLabAdmins: (state) => state.labAdmins,
  getLabManagers: (state) => state.labManagers,
  getUserAccountUpdateStatus: (state) => state.userAccountUpdateStatus,
  getUserAccountDeleteStatus: (state) => state.userAccountDeleteStatus,
  getUserAccountStatusUpdated: (state) => state.userAccountStatusUpdated,
  getUserAccountDataById: (state) => state.userAccountStatusUpdated,
  getUserPasswordResetStatus: (state) => state.userPasswordResetStatus,
  toggleEmailNotificationStatus: (state) => state.toggleEmailNotificationStatus,
  getEmailNotification: (state) => state.emailNotificationSettings,
};

const mutations = {
  setAllUsers(state, users) {
    state.users = users;
  },
  setAllUserAccounts(state, data) {
    state.allUserAccounts = data;
  },
  setUserAccountAddStatus(state, data) {
    state.userAccountAddStatus = data;
  },
  setUserAccountErrors(state, data) {
    state.errorData = data;
  },
  setEmailNotification(state, data) {
    state.emailNotificationSettings = data;
  },
  setLabAdmins(state, data) {
    state.labAdmins = data;
  },
  setLabManagers(state, data) {
    state.labManagers = data;
  },
  setUserAccountUpdateStatus(state, data) {
    state.userAccountUpdateStatus = data;
  },
  setUserAccountDeleteStatus(state, data) {
    state.userAccountDeleteStatus = data;
  },
  setUserAccountStatusUpdated(state, data) {
    state.userAccountStatusUpdated = data;
  },
  setUserAccountDataById(state, data) {
    state.userAccountStatusUpdated = data;
  },
  setUserPasswordResetStatus(state, data) {
    state.userPasswordResetStatus = data;
  },
  setToggleEmailNotificationStatus(state, status) {
    state.toggleEmailNotificationStatus = status;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  async refreshErrors(context) {
    context.commit("userAccounts/setUserAccountErrors", {}, { root: true });
  },
  fetchAllUserAccounts: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true });

      const response = await axios.get("/users", { params });

      commit("setAllUsers", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  async addUserAccount({ commit, dispatch }, params) {
    try {
      await axios.post("users", params);
      commit("userAccounts/setUserAccountAddStatus", true, {
        root: true,
      });
    } catch (error) {
      commit("userAccounts/setUserAccountAddStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },
  async fetchLabUserAccounts({ commit }, params = {}) {
    try {
      const { data } = await axios.get("/users/lab/user", { params });
      commit("setAllUserAccounts", data);
    } catch (err) {
      console.log(err);
      commit("setAllUserAccounts", { page: 1, data: [], total: 0 });
    }
  },
  async fetchEmailNotification({ commit }, { user_type }) {
    try {
      const { data } = await axios.get(`/email-event/${user_type}`);
      commit("setEmailNotification", data.data);
    } catch (err) {
      console.log(err);
      commit("setEmailNotification", { page: 1, data: [], total: 0 });
    }
  },
  async fetchLabAdmins(context, params = {}) {
    try {
      context.commit("userAccounts/setLabAdmins", {}, { root: true });
      const response = await axios.get("/lab-admins", { params });
      context.commit("userAccounts/setLabAdmins", response.data, {
        root: true,
      });
    } catch (err) {
      console.log(err);
      context.commit(
        "userAccounts/setLabAdmins",
        { page: 1, data: [], total: 0 },
        { root: true }
      );
    }
  },
  async fetchLabManagers(context, params = {}) {
    try {
      context.commit("userAccounts/setLabManagers", {}, { root: true });
      const response = await axios.get("/lab-users", { params });
      context.commit("userAccounts/setLabManagers", response.data, {
        root: true,
      });
    } catch (err) {
      console.log(err);
      context.commit(
        "userAccounts/setLabManagers",
        { page: 1, data: [], total: 0 },
        { root: true }
      );
    }
  },
  async fetchUserAccountById(context, params) {
    try {
      const response = await axios.get("/users/" + params.id);
      context.commit(
        "userAccounts/setUserAccountDataById",
        response.data.data,
        { root: true }
      );
    } catch (err) {
      console.log(err);
      context.commit("userAccounts/setUserAccountDataById", {}, { root: true });
    }
  },
  async updateUserAccount(context, params) {
    try {
      await axios.put("users/" + params.id, params);
      context.commit("userAccounts/setUserAccountUpdateStatus", true, {
        root: true,
      });
    } catch (error) {
      context.commit("userAccounts/setUserAccountUpdateStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await context.dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },
  async toggleEmailNotifications({ commit, dispatch }, { id, data }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setToggleEmailNotificationStatus", false);
      await axios.put(`users/${id}/settings`, data);
      commit("setToggleEmailNotificationStatus", true);
    } catch (err) {
      commit("setToggleEmailNotificationStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  // add lab admin account details
  async addLabAdminAccount(context, params) {
    try {
      context.commit("userAccounts/setUserAccountAddStatus", false, {
        root: true,
      });
      await axios.post("lab-admins", params);
      context.commit("userAccounts/setUserAccountAddStatus", true, {
        root: true,
      });
    } catch (error) {
      context.commit("userAccounts/setUserAccountAddStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await context.dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },
  // update lab admin account details
  async updateLabAdminAccount(context, params) {
    try {
      context.commit("userAccounts/setUserAccountUpdateStatus", false, {
        root: true,
      });
      await axios.patch("lab-admins/" + params.id, params);
      context.commit("userAccounts/setUserAccountUpdateStatus", true, {
        root: true,
      });
    } catch (error) {
      context.commit("userAccounts/setUserAccountUpdateStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await context.dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },
  // add lab manager account details
  async addLabManagerAccount(context, params) {
    try {
      context.commit("userAccounts/setUserAccountAddStatus", false, {
        root: true,
      });
      await axios.post("lab-managers", params);
      context.commit("userAccounts/setUserAccountAddStatus", true, {
        root: true,
      });
    } catch (error) {
      context.commit("userAccounts/setUserAccountAddStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await context.dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },
  // update lab manager account details
  async updateLabManagerAccount(context, params) {
    try {
      context.commit("userAccounts/setUserAccountUpdateStatus", false, {
        root: true,
      });
      await axios.patch("lab-managers/" + params.id, params);
      context.commit("userAccounts/setUserAccountUpdateStatus", true, {
        root: true,
      });
    } catch (error) {
      context.commit("userAccounts/setUserAccountUpdateStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await context.dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },

  async deleteUserAccount(context, params) {
    try {
      context.commit("userAccounts/setUserAccountDeleteStatus", false, {
        root: true,
      });
      await axios.delete("users/" + params.id);
      context.commit("userAccounts/setUserAccountDeleteStatus", true, {
        root: true,
      });
    } catch (error) {
      context.commit("userAccounts/setUserAccountDeleteStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await context.dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },

  async updateUserAccountStatus(context, params) {
    try {
      context.commit("userAccounts/setUserAccountStatusUpdated", false, {
        root: true,
      });
      await axios.put("users/" + params.id + "/status", params);
      context.commit("userAccounts/setUserAccountStatusUpdated", true, {
        root: true,
      });
    } catch (error) {
      context.commit("userAccounts/setUserAccountStatusUpdated", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await context.dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },
  async resetUserAccountPassword({ commit, dispatch }, params) {
    try {
      commit("userAccounts/setUserPasswordResetStatus", false, {
        root: true,
      });
      await axios.put("users/" + params.id + "/reset-password");
      commit("userAccounts/setUserPasswordResetStatus", true, {
        root: true,
      });
    } catch (error) {
      commit("userAccounts/setUserPasswordResetStatus", false, {
        root: true,
      });
      if (error.response) {
        let errorData = await dispatch(
          "userAccounts/errorResponse",
          error.response,
          { root: true }
        );
        commit("userAccounts/setUserAccountErrors", errorData, {
          root: true,
        });
      }
    }
  },
  errorResponse: async (context, errorResponse) => {
    let errorStatusCode = errorResponse.status;
    let errorData = {};

    switch (errorStatusCode) {
      case 422:
        var errors = errorResponse.data.errors;
        errors.details.forEach((detail) => {
          if (!errorData[detail.key]) {
            errorData[detail.key] = detail.message;
          }
        });
        break;
      case 401:
      case 403:
        errorData["critical_error"] = errorResponse.data.message;
        break;

      default:
        errorData["critical_error"] = errorResponse.data.message;
        break;
    }
    return errorData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
