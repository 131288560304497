import axios from "@/config/axios"

const initialState = () => {
  return {
    testPanelsByLabId: [],
    allTestPanels: [],
    panelAddStatus: false,
    panelAddErrors: null,
    updateTestPanelStatus: null,
    defaultTestPanels: null,
  }
}

const state = initialState()

const getters = {
  getPanelAddStatus: (state) => state.panelAddStatus,
  getPanelAddErrors: (state) => state.panelAddErrors,
  getAllTestPanels: (state) => state.allTestPanels,
  getTestPanelsByLabId: (state) => state.testPanelsByLabId,
  getDefaultTestPanels: (state) => state.defaultTestPanels,
  getTestPanelUpdateStatus: (state) => state.updateTestPanelStatus,
}

const mutations = {
  setPanelAddStatus(state, status) {
    state.panelAddStatus = status
  },
  setAllTestPanels(state, panels) {
    state.allTestPanels = panels
  },
  setTestPanelsByLabId(state, panel) {
    state.testPanelsByLabId = panel
  },
  setPanelAddErrors(state, errors) {
    state.panelAddErrors = errors
  },
  setDefaultTestPanels(state, data) {
    state.defaultTestPanels = data
  },
  setTestPanelUpdateStatus(state, data) {
    state.updateTestPanelStatus = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  fetchTestPanelsByLabId: async (context, params) => {
    try {
      context.dispatch("errors/clear", [], { root: true })
      context.commit("testpanel/setPanelAddErrors", null, { root: true })
      let labId = params.lab_id
      if (params.code) {
        params.include_test_details = params.details
        delete params.details
        let response = await axios.get(`/labs/${labId}/test-panels`, {
          params,
        })
        context.commit("testpanel/setTestPanelsByLabId", response.data, {
          root: true,
        })
      } else {
        let response = await axios.get(
          `/labs/${labId}/test-panels?include_test_details=${params.details}`
        )
        context.commit("testpanel/setTestPanelsByLabId", response.data, {
          root: true,
        })
      }
    } catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  addTestPanels: async (context, params) => {
    try {
      context.commit("testpanel/setPanelAddErrors", null, { root: true })
      context.dispatch("errors/clear", [], { root: true })
      context.commit("testpanel/setPanelAddStatus", false, { root: true })
      await axios.post("/labs/test-panels", params)
      context.commit("testpanel/setPanelAddStatus", true, { root: true })
    } catch (error) {
      context.commit("testpanel/setPanelAddStatus", false, { root: true })
      if (error.response) {
        let errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          { root: true }
        )
        context.commit("testpanel/setPanelAddErrors", errorData, {
          root: true,
        })
      }
    }
  },
  fetchTestPanels: async (context, params) => {
    try {
      context.dispatch("errors/clear", [], { root: true })
      context.commit("testpanel/setPanelAddErrors", null, { root: true })
      if (params.panel_type) {
        let response = await axios.get(
          `/test-panels?code=${params.panel_type}&include_test_details=${params.details}`
        )
        context.commit("testpanel/setAllTestPanels", response.data, {
          root: true,
        })
      } else {
        let response = await axios.get("/test-panels", { params })
        context.commit("testpanel/setAllTestPanels", response.data, {
          root: true,
        })
      }
    } catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  fetchDefaultTestPanels: async (context, params) => {
    try {
      context.dispatch("errors/clear", [], { root: true })
      context.commit("testpanel/setDefaultTestPanels", null, { root: true })
      let response = await axios.get("/default-test-panels", params)
      context.commit("testpanel/setDefaultTestPanels", response.data, {
        root: true,
      })
    } catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  changeTestPanelStatus: async (context, params) => {
    try {
      context.commit("testpanel/setTestPanelUpdateStatus", null, {
        root: true,
      })
      let test_panel_id = params.test_panel_id
      delete params.test_panel_id
      let response = await axios.patch(
        "/test-panels/" + "status/" + test_panel_id,
        params
      )
      context.commit("testpanel/setTestPanelUpdateStatus", response.data, {
        root: true,
      })
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
    }
  },
  errorResponse: async (context, errorResponse) => {
    let errorStatusCode = errorResponse.status
    let errorData = {}

    switch (errorStatusCode) {
      case 422:
        var errors = errorResponse.data.errors
        errors.details.forEach((detail) => {
          if (!errorData[detail.key]) {
            errorData[detail.key] = detail.message
          }
        })
        break
      case 401:
      case 403:
        errorData["critical_error"] = errorResponse.data.message
        break

      default:
        errorData["critical_error"] = errorResponse.data.message
        break
    }
    return errorData
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
