import axios from "@/config/axios";

const initialState = () => {
  return {
    allInstrumentTestConfig: {},
    isActiveStatus: false,
    singleTestConfig: {},
    testConfigCreationStatus: false,
    testConfigUpdateStatus: false,
  };
};

const state = initialState();

const getters = {
  getAllInstrumentTestConfig: (state) => state.allInstrumentTestConfig,
  getIsActiveStatus: (state) => state.isActiveStatus,
  getSingleBloodQcConfig: (state) => state.singleTestConfig,
  getQcConfigCreationStatus: (state) => state.testConfigCreationStatus,
  getQcConfigUpdateStatus: (state) => state.testConfigUpdateStatus,
};

const mutations = {
  setAllBloodTestConfig(state, data) {
    state.allInstrumentTestConfig = data;
  },

  setIsActiveStatus(state, status) {
    state.isActiveStatus = status;
  },
  setSingleTestConfig(state, data) {
    state.singleTestConfig = data;
  },
  setTestCofigCreationStatus(state, data) {
    state.testConfigCreationStatus = data;
  },
  setTestCofigUpdateStatus(state, data) {
    state.testConfigUpdateStatus = data;
  },
  reset(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllBloodTestConfig: async ({ commit }, params) => {
    try {
      commit("setAllBloodTestConfig", null);
      const response = await axios.get("/blood-instrument-tests", { params });
      commit("setAllBloodTestConfig", response.data.response);
    } catch (err) {
      commit("setAllBloodTestConfig", null, { root: true });
    }
  },
  async toggleIsActiveStatus({ commit, dispatch }, { id, data }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setIsActiveStatus", false);
      await axios.patch(`/blood-instrument-tests/active/${id}`, data);
      commit("setIsActiveStatus", true);
    } catch (err) {
      commit("setIsActiveStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  fetchSingleTestConfig: async ({ commit }, id) => {
    try {
      commit("setSingleTestConfig", null);
      let response = await axios.get(`/blood-instrument-tests/${id}`);
      commit("setSingleTestConfig", response.data.data);
    } catch (err) {
      commit("setSingleTestConfig", null);
    }
  },
  createBloodTestConfig: async ({ commit, dispatch }, params) => {
    try {
      commit("setTestCofigCreationStatus", false);
      await axios.post("/blood-instrument-tests", params);
      commit("setTestCofigCreationStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  updateBloodTestConfig: async ({ commit, dispatch }, params) => {
    let id = params.id;
    delete params.id;
    try {
      commit("setTestCofigUpdateStatus", false);
      await axios.put("/blood-instrument-tests/" + id, params);
      commit("setTestCofigUpdateStatus", true);
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
