import axios from "@/config/axios";

const initialState = () => {
  return {
    allBatches: [],
    singleBatch: null,
    batchAddStatus: false,
    batchDeleteStatus: null,
    batchArchiveStatus: null,
    batchUpdateStatus: null,
    batchDownloadStatus: null,
    batchCreateValidationErrors: null,
  }
};

const state = initialState();

const getters = {
  getAllBatches: state => state.allBatches,
  getSingleBatch: state => state.singleBatch,
  getBatchAddStatus: state => state.batchAddStatus,
  getBatchUpdateStatus: state => state.batchUpdateStatus,
  getBatchDeleteStatus: state => state.batchDeleteStatus,
  getBatchArchiveStatus: state => state.batchArchiveStatus,
  getBatchDownloadStatus: state => state.batchDownloadStatus,
  getBatchCreateValidationErrors: state => state.batchCreateValidationErrors,
};

const mutations = {
  setAllBatches (state, batches) {
    state.allBatches = batches
  },
  setSingleBatch (state, data) {
    state.singleBatch = data
  },
  setBatchAddStatus (state, status) {
    state.batchAddStatus = status
  },
  setBatchUpdateStatus (state, status) {
    state.batchUpdateStatus = status
  },
  setBatchDeleteStatus (state, data) {
    state.batchDeleteStatus = data
  },
  setBatchArchiveStatus (state, data) {
    state.batchArchiveStatus = data
  },
  setBatchDownloadStatus (state, status) {
    state.batchDownloadStatus = status
  },
  setBatchCreateValidationErrors (state, data) {
    state.batchCreateValidationErrors = data
  },
  reset: state => {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  fetchAllBatches: async (context, params) => {
    try {
      context.dispatch("errors/clear", [], { root: true });

      let response = await axios.get("/batches/", { params });

      context.commit("batches/setAllBatches", response.data, { root: true });
    }
    catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  fetchSingleBatch: async (context, batch_id) => {
    try {
      context.commit("batches/setSingleBatch", null, { root: true });

      let response = await axios.get("/batches/" + batch_id);
      context.commit("batches/setSingleBatch", response.data.data, { root: true });
    }
    catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  storeBatch: async (context, params) => {
    try {
      context.dispatch("errors/clear", [], { root: true });
      context.commit("batches/setBatchCreateValidationErrors", null, { root: true })
      context.commit("batches/setBatchAddStatus", false, { root: true });

      await axios.post("/batches", params);

      context.commit("batches/setBatchAddStatus", true, { root: true });
    }
    catch (error) {
      if (error.response) {
        let errorData = await context.dispatch("cases/errorResponse", error.response, { root: true });
        context.commit("batches/setBatchCreateValidationErrors", errorData, { root: true })
      }
    }
  },
  archiveBatch: async (context, params) => {
    try {
      context.dispatch("errors/clear", [], { root: true });
      context.commit("batches/setBatchArchiveStatus", false, { root: true });

      await axios.patch("/batches/archived/" + params.batch_id, params.data);

      context.commit("batches/setBatchArchiveStatus", true, { root: true });
    }
    catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  updateBatch: async (context, params) => {
    try {
      context.dispatch("errors/clear", [], { root: true });
      context.commit("batches/setBatchUpdateStatus", false, { root: true });

      await axios.patch("/batches/" + params.batch_id, params.data);

      context.commit("batches/setBatchUpdateStatus", true, { root: true });
    } catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
  downloadBatch: async (context, batch_id) => {
    try {
      context.dispatch("errors/clear", [], { root: true });
      context.commit("batches/setBatchDownloadStatus", false, { root: true });

      let response = await axios.get("/batches/" + batch_id + "/download-as-csv");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'batches.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      context.commit("batches/setBatchDownloadStatus", true, { root: true });
    } catch (error) {
      if (error.response) {
        context.dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
