import axios from "@/config/axios";
import * as PrivateAxios from "axios";

const initialState = () => {
  return {
    attachmentUpdateResponse: "",
    generateReportsStatus: {},
    generateToxicologyReportsStatus: {},
    generatedReportsFromFileUploads: {},
    generateFailedReportStatus: {},
    singleFileUploadStatus: {},
    singleFileUploadErrors: {},
    fileUploadErrors: {},
    fileDeleteStatus: false,
    selectedResultFileData: {},
    fileDownloadUrl: null,
    fileDownloadUrlError: null,
    covidStatusUpdated: null,
    covidStatusUpdateError: null,
    updateToxicologyTestReportStatus: false,

    processReportStatus: false,
    reportEditStatus: false,
    reportEditBlockingReason: "",
    downloadResultFilesStatus: false,
    fileUploadFormatErrors: {},
    generateResultsSummaryStatus: {},
  };
};

const state = initialState();

const getters = {
  getAttachmentUpdateResponse: (state) => state.attachmentUpdateResponse,
  getGenerateReportStatus: (state) => state.generateReportsStatus,
  getGenerateToxicologyReportStatus: (state) =>
    state.generateToxicologyReportsStatus,
  getGeneratedReportsFromFileUploads: (state) =>
    state.generatedReportsFromFileUploads,
  getGenerateFailedReportStatus: (state) => state.generateFailedReportStatus,
  getSingleFileUploadStatus: (state) => state.singleFileUploadStatus,
  getFileUploadErrors: (state) => state.fileUploadErrors,
  getFileDeleteStatus: (state) => state.fileDeleteStatus,
  getFileDeleteErrors: (state) => state.fileDeleteErrors,
  getSelectedResultFileData: (state) => state.selectedResultFileData,
  getSingleFileUploadErrors: (state) => state.singleFileUploadErrors,
  getSampleNameTestResultData: (state) => state.sampleNameTestResultData,
  getFileDownloadUrl: (state) => state.fileDownloadUrl,
  getFileDownloadUrlError: (state) => state.fileDownloadUrlError,
  getCovidStatusUpdated: (state) => state.covidStatusUpdated,
  getCovidStatusUpdateError: (state) => state.covidStatusUpdateError,

  getUpdateToxicologyTestReportStatus: (state) =>
    state.updateToxicologyTestReportStatus,

  getProcessReportStatus: (state) => state.processReportStatus,
  getEditReportAccess: (state) => state.reportEditStatus,
  getReportEditBlockingReason: (state) => state.reportEditBlockingReason,
  getDownloadResultFilesStatus: (state) => state.downloadResultFilesStatus,
  getFileUploadFormatErrors: (state) => state.fileUploadFormatErrors,
  getGenerateResultsStauts: (state) => state.generateResultsSummaryStatus,
};

const mutations = {
  setAttachmentUpdateResponse(state, data) {
    state.attachmentUpdateResponse = data;
  },

  setGenerateReportStatus(state, status) {
    state.generateReportsStatus = status;
  },
  setGenerateToxicologyReportStatus(state, status) {
    state.generateToxicologyReportsStatus = status;
  },
  setGeneratedReportsFromFileUploads(state, data) {
    state.generatedReportsFromFileUploads = data;
  },
  setGenerateFailedReportStatus(state, status) {
    state.generateFailedReportStatus = status;
  },
  setSingleFileUploadStatus(state, status) {
    state.singleFileUploadStatus = status;
  },

  setFileDeleteStatus(state, data) {
    state.fileDeleteStatus = data;
  },
  setFileUploadErrors(state, errors) {
    state.fileUploadErrors = errors;
  },
  setFileDeleteErrors(state, errors) {
    state.fileDeleteErrors = errors;
  },

  setSelectedResultFileData(state, data) {
    state.selectedResultFileData = data;
  },
  setSingleFileUploadErrors(state, data) {
    state.singleFileUploadErrors = data;
  },

  setSampleNameTestResultData(state, data) {
    state.sampleNameTestResultData = data;
  },

  setFileDownloadUrl(state, data) {
    state.fileDownloadUrl = data;
  },
  setFileDownloadUrlError(state, data) {
    state.fileDownloadUrlError = data;
  },

  setCovidStatusUpdated(state, data) {
    state.covidStatusUpdated = data;
  },
  setCovidStatusUpdateError(state, data) {
    state.covidStatusUpdateError = data;
  },

  setUpdateToxicologyTestReportStatus(state, status) {
    state.updateToxicologyTestReportStatus = status;
  },
  setProcessReportStatus(state, status) {
    state.processReportStatus = status;
  },
  setEditReportAccess(state, status) {
    state.reportEditStatus = status;
  },
  setReportEditBlockingReason(state, status) {
    state.reportEditBlockingReason = status;
  },
  setDownloadResultFilesStatus(state, status) {
    state.downloadResultFilesStatus = status;
  },
  setFileUploadError(state, status) {
    state.fileUploadFormatErrors = status;
  },
  setResultsGenerateStatus(state, status) {
    state.generateResultsSummaryStatus = status;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  resetErrors: ({ commit }) => {
    commit("setSingleFileUploadErrors", null);
  },
  uploadSingleFile: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", null, { root: true });
      commit("setSingleFileUploadStatus", null);
      commit("setSingleFileUploadErrors", null);
      const response = await axios.post("/result-files-covid", params);
      commit("setSingleFileUploadStatus", response.data);
    } catch (error) {
      if (error.response) {
        commit("setFileUploadError", error.response);
        const errors = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });

        commit("setSingleFileUploadErrors", errors);
      }
    }
  },

  fetchFileDownloadUrl: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", null, { root: true });
      commit("setFileDownloadUrl", null);
      const response = await axios.post("/file-uploads/download-url", params);
      commit("setFileDownloadUrl", response.data.data);
    } catch (error) {
      if (error.response) {
        dispatch(
          "setFileDownloadUrlError",
          error.response.message || "Something went wrong on downloading data"
        );
      }
    }
  },

  updateCovidStatus: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", null, { root: true });
      commit("setCovidStatusUpdated", false);
      commit("setCovidStatusUpdateError", null);
      const response = await axios.post(
        "/result-files-covid/" +
          params.result_file_id +
          "/sample-names/" +
          params.sample_name_id +
          "/" +
          params.test_result_id +
          "/finalize-report",
        params
      );
      commit("setCovidStatusUpdated", response.data);
    } catch (error) {
      if (error.response) {
        commit("setCovidStatusUpdated", null);
        commit(
          "setCovidStatusUpdateError",
          error.response.message || "Something went wrong on downloading data"
        );
      }
    }
  },

  downloadReportCsvData: async ({ commit }, params) => {
    try {
      const response = await axios.get(
        "/result-files-covid/" +
          params.result_file_id +
          "/sample-names/" +
          params.sample_name_id +
          "/" +
          params.test_result_id +
          "/download-as-csv"
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reoprt_" + params.sample_name + ".csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  },

  checkReportEditStatus: async ({ commit }, params) => {
    try {
      commit("setEditReportAccess", false);

      const response = await axios.post(
        "/result-files-covid/reports/" +
          params.sample_name_id +
          "/check-edit-access"
      );

      let editStatus =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.edit_status
          ? true
          : false;
      let editReason =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.edit_blocking_reason
          ? response.data.data.edit_blocking_reason
          : "";

      commit("setEditReportAccess", editStatus);
      commit("setReportEditBlockingReason", editReason);
    } catch (error) {
      console.log(error);
    }
  },

  deleteSingleFile: async ({ commit, dispatch }, params) => {
    try {
      commit("setFileDeleteStatus", false);
      await axios.delete("/result-files-covid/" + params.id, { data: params });
      commit("setFileDeleteStatus", true);
    } catch (error) {
      commit("setFileDeleteStatus", false);
      if (error.response) {
        dispatch("errorResponse", error.response);
      }
    }
  },
  generateResultsForReportsSummary: async ({ commit }, params) => {
    try {
      commit("setResultsGenerateStatus", null);
      const response = await axios.post(
        "/result-files-covid/" + params.id + "/generate-summary"
      );
      commit("setResultsGenerateStatus", response.data);
    } catch (error) {
      if (error.response) {
        commit("setResultsGenerateStatus", null);
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  generateToxicologyReports: async ({ commit, dispatch }, params) => {
    try {
      commit("setGenerateReportStatus", null);
      const response = await axios.post(
        "/result-files-covid/" + params.id + "/generate-report",
        params.specific_accession_numbers
      );

      commit("setGenerateReportStatus", response.data);
    } catch (error) {
      if (error.response) {
        commit("setGenerateReportStatus", null);
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  generateReportsFromFileUploads: async ({ commit }, params) => {
    try {
      commit("setGenerateReportStatus", null);
      let order_id = params.id;
      delete params.id;
      const response = await axios.post(
        "/result-files-covid/" + order_id + "/generate-report",
        params
      );
      commit("setGenerateReportStatus", response.data);
    } catch (error) {
      if (error.response) {
        commit("setGenerateReportStatus", null);
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  fetchGeneratedReportsFromFileUploads: async (
    { commit, dispatch },
    params
  ) => {
    try {
      commit("setGeneratedReportsFromFileUploads", null);
      const id = params.id;
      delete params.id;
      const response = await axios.get(
        `/result-files-covid/${id}/sample-names`,
        { params }
      );
      commit("setGeneratedReportsFromFileUploads", response.data);
      commit("setSelectedResultFileData", response.data.result_file_data);
    } catch (error) {
      if (error.response) {
        commit("setGeneratedReportsFromFileUploads", null);
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  fetchSampleNameTestResultsData: async ({ commit }, params) => {
    try {
      commit("setSampleNameTestResultData", null);
      const response = await axios.get(
        "/result-files-covid/" +
          params.result_file_id +
          "/sample-names/" +
          params.sample_name_id +
          "/" +
          params.test_results_id
      );
      commit("setSampleNameTestResultData", response.data.data);
    } catch (error) {
      if (error.response) {
        commit("setSampleNameTestResultData", null);
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  regenerateFailedReportsFromFileUploads: async (
    { commit, dispatch },
    params
  ) => {
    try {
      commit("setGenerateFailedReportStatus", null);
      const response = await axios.post(
        "/result-files-covid/" +
          params.result_file_id +
          "/generate-report/" +
          params.sample_name_id
      );
      commit("setGenerateFailedReportStatus", response.data);
    } catch (error) {
      if (error.response) {
        commit("setGenerateFailedReportStatus", null);
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  updateToxicologyTestReports: async ({ commit, dispatch }, params) => {
    try {
      commit("setUpdateToxicologyTestReportStatus", false);
      await axios.patch(
        `/result-files-covid/${params.result_file_id}/sample-names/${params.sample_name_id}/${params.test_result_id}/values`,
        params.data
      );
      commit("setUpdateToxicologyTestReportStatus", true);
    } catch (err) {
      commit("setUpdateToxicologyTestReportStatus", false);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        });
      }
    }
  },

  processReport: async ({ commit, dispatch }, report_id) => {
    try {
      await dispatch("errors/clear", null, { root: true });
      commit("setProcessReportStatus", false);

      await axios.post(`/result-files-covid/${report_id}/process-file`);
      commit("setProcessReportStatus", true);
    } catch (error) {
      commit("setProcessReportStatus", false);
    }
  },

  // for uti reports data
  fetchSampleNameUTITestResultsData: async (
    { commit, dispatch },
    { result_file_id, sample_name_id }
  ) => {
    try {
      commit("setSampleNameTestResultData", null);
      const response = await axios.get(
        `/result-files-covid/${result_file_id}/sample-names/${sample_name_id}/test-report/preview`
      );

      commit("setSampleNameTestResultData", response.data);
    } catch (error) {
      if (error.response) {
        commit("setSampleNameTestResultData", null);
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  downloadResultFilesData: async ({ commit }, params) => {
    try {
      commit("setDownloadResultFilesStatus", false);
      const response = await axios.get("/result-files-covid/download-as-csv", {
        params,
      });
      commit("setDownloadResultFilesStatus", true);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "resultFiles.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      commit("setDownloadResultFilesStatus", false);
      console.log(error);
    }
  },
  fetchCovidResultFileData: async (
    { commit, dispatch },
    { result_file_id, sample_name_id }
  ) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setSampleNameTestResultData", null);
      const response = await axios.get(
        `/result-files-covid/${result_file_id}/sample-names/${sample_name_id}/test-report/preview`
      );
      commit("setSampleNameTestResultData", response);
    } catch (error) {
      if (error.response) {
        commit("setSampleNameTestResultData", null);
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
